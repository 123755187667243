import React, { lazy } from "react";
import "./ItcDetailsWindow.scss";
import { tx } from "core/intl";
import { useWindowContext } from "../../../core/desktop/WindowViewContext";
import { defineWindowDetails, useInitWindowSize } from "core/desktop";
import { WindowLazyContent } from "core/desktop/WindowLazyContent";

type ItcDetailsWindowProps = Omit<React.ComponentProps<typeof ItcDetailsWindowContent>, "window">;

const ItcDetailsWindowContent = lazy(() =>
  import("./ItcDetailsWindowContent").then(m => ({ default: m.ItcDetailsWindowContent }))
);

defineWindowDetails(ItcDetailsWindow, {
  icon: "fas fa-traffic-light",
  title: tx("Szczegóły sterownika"),
  contextHelpCode: 'ItcDetailsWindow',
  noPadding: true,
  allowInPresentationMode: true,
});

export function ItcDetailsWindow(props: ItcDetailsWindowProps) {
  useInitWindowSize(850, 600);
  const window = useWindowContext();

  return (
    <WindowLazyContent>
      <ItcDetailsWindowContent {...props} window={window} />
    </WindowLazyContent>
  );
}
