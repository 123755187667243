import { bundledAppVersionInfo } from "core/bundledAppVersionInfo";
import { Text } from "core/content";
import { FeedbackMessage } from "core/content/FeedbackMessage";
import { Stack } from "core/layout";
import { SimpleContainer } from "core/layout/SimpleContainer";
import { wsHubHelper } from "core/ws/wsHub";
import React from "react";

interface StandardErrorBoundaryProps {
  category: string;
  description: string;
  children: React.ReactNode;
}

interface StandardErrorBoundaryState {
  hasError: boolean;
  error?: any;
  formattedError?: string;
  isDynamicFetchModuleError?: boolean;
}

export class StandardErrorBoundary extends React.Component<StandardErrorBoundaryProps, StandardErrorBoundaryState> {
  constructor(props: StandardErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: any): Partial<StandardErrorBoundaryState> | null {
    const formattedError = formatError(error);
    const isDynamicFetchModuleError = checkForDynamicFetchModuleError(formattedError);
    return { hasError: true, error, formattedError, isDynamicFetchModuleError };
  }

  componentDidCatch(error: any) {
    console.error(error);
    const formattedError = wsHubHelper.formatError(error);
    const isDynamicFetchModuleError = checkForDynamicFetchModuleError(formattedError);
    if (isDynamicFetchModuleError) {
      console.log("Skipped to report error client due to detected dynamic fetch module error");
    } else {
      wsHubHelper.tryReportErrorClient({
        userAgent: navigator.userAgent,
        appVersion: bundledAppVersionInfo.fullVersion,
        details: `[${this.props.category}:${this.props.description}] ${formattedError}`,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <SimpleContainer padding>
          {this.state.isDynamicFetchModuleError ? (
            <FeedbackMessage kind="error" header="Błąd wczytywania modułu aplikacji">
              <Stack gap="4px">
                <Text block>Upewnij się, że masz połączenie z internetem.</Text>
                <Text block>
                  Problem może być również spowodowany stosowaniem wtyczek przeglądarki ingerujących w działanie strony
                  np. AdBlock, uBlock - upewnij się, że aplikacja jest wykluczona z działania tych wtyczek.
                </Text>
              </Stack>
            </FeedbackMessage>
          ) : (
            <FeedbackMessage kind="error" header="Nieprzewidziany błąd zawartości">
              <Text block>Szczegóły błędu zostały automatycznie zarejestrowane do analizy</Text>
              <Text block small muted className="text-pre mt-8">
                {this.state.formattedError}
              </Text>
            </FeedbackMessage>
          )}
        </SimpleContainer>
      );
    }

    return this.props.children;
  }
}

function formatError(error: any) {
  if (error instanceof Error) {
    return `${error.message}`;
  }

  return `${error}`;
}

function checkForDynamicFetchModuleError(errorStr: string): boolean {
  return errorStr.includes("Failed to fetch dynamically imported module");
}
