import { DatepickerInput } from "core/forms";
import React, { useContext } from "react";
import { FormContext } from "./FormContext";

type DateInputProps = React.ComponentProps<typeof DatepickerInput>;

interface DateFieldProps extends Omit<DateInputProps, "value" | "onChange"> {
  name: string;
  inputName?: string;
}

export const DateField = React.memo(function DateField(props: DateFieldProps) {
  const form = useContext(FormContext);
  let { name, inputName, ...restAttrs } = props;
  const value = form.model[name];

  return (
    <DatepickerInput
      {...restAttrs}
      onChange={(value) => form.handleFieldChange(name, value)}
      value={value}
      name={inputName}
      disabled={
        props.hasOwnProperty("disabled") ? props.disabled : form.disabled
      }
    />
  );
});
