import React, { Dispatch, SetStateAction, useContext } from 'react';

export interface IFormContext<T = any> {
  model: T;
  disabled: boolean;
  parentFieldName: string;
  nestedFormField?: string;
  nestedFormFieldIndex?: number;
  handleFieldChange<K extends keyof T>(key: K, value: T[K]): any;
  handleModelChange: Dispatch<SetStateAction<T>>;
  handleSubmit: () => any;
}

function noop() {}

export const DefaultFormContextValue: IFormContext = {
  model: {},
  disabled: false,
  parentFieldName: "",
  handleFieldChange: noop,
  handleModelChange: noop,
  handleSubmit: noop
}

export const FormContext = React.createContext<IFormContext>(DefaultFormContextValue);

export function useFormContext<T = any>() {
  const formCtx = useContext(FormContext);
  return formCtx as IFormContext<T>;
}
