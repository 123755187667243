import { WindowInstance } from "./Window";
import { WindowStaticProps } from "./WindowTypes";
import { desktopState } from "../global/desktopState";
import { systemState } from "../global/systemState";
import { WindowSerializedState } from "./desktopSave/DesktopSerializedState";

interface OpenWindowArgs<TProps> {
  component: React.ComponentType<TProps> & WindowStaticProps;
  props: TProps;
  restoreState?: WindowSerializedState;
}

class DesktopManager {
  openWindow<TProps>(args: OpenWindowArgs<TProps>): WindowInstance<TProps> {
    if (systemState.state.isPresentationModeActive && !args.component.allowInPresentationMode) {
      return {} as any;
    }

    if (args.component.getNativeWindowUrl && this.shouldAutomaticallyOpenNativeWindow(args)) {
      const url = args.component.getNativeWindowUrl(args.props || {});
      this.openNativeWindow(url);
      return {} as any;
    } else {
      const wnd = new WindowInstance<TProps>(args.component, args.props, args.restoreState);
      this.addWindow(wnd);
      this.activate(wnd);
      return wnd;
    }
  }

  private shouldAutomaticallyOpenNativeWindow(args: OpenWindowArgs<any>): boolean {
    if (!args.component.getNativeWindowUrl) return false;
    if (systemState.state.nativeWindowMode) return true;
    if (args.component.shouldOpenNativeWindow && args.component.shouldOpenNativeWindow()) return true;

    return false;
  }

  private isLockedDueToPresentationMode(window: WindowInstance): boolean {
    return systemState.state.isPresentationModeActive && Boolean(window.restoreState);
  }

  openNativeWindow(relativePath: string): Window | null {
    if (systemState.state.isPresentationModeActive) {
      return null;
    }

    let url = window.location.href;
    if (window.location.hash) {
      url = url.replace(window.location.hash, "");
    }

    url += `#${relativePath}`;

    return window.open(url, "_blank", "menubar=0,titlebar=0");
  }

  addWindow(window: WindowInstance) {
    desktopState.addWindow(window);
  }

  minimizeWindow(window: WindowInstance) {
    if (this.isLockedDueToPresentationMode(window)) {
      return;
    }
    desktopState.minimizeWindow(window);
  }

  closeWindow(window: WindowInstance, result?: any) {
    if (this.isLockedDueToPresentationMode(window)) {
      return;
    }

    window.closeHandlers.forEach(h => h(result));
    desktopState.closeWindow(window);
  }

  activate(window: WindowInstance) {
    desktopState.activateWindow(window);
  }

  activateMostRecentWindow() {
    desktopState.activateMostRecentWindow();
  }

  setTitle(window: WindowInstance, title: string) {
    desktopState.setWindowTitle(window, title);
  }

  setTitleExtension(window: WindowInstance, titleExtension: string | undefined) {
    desktopState.setWindowTitleExtension(window, titleExtension);
  }

  closeAllWindows(ignorePresentationModeLock?: boolean) {
    if (!ignorePresentationModeLock && systemState.state.isPresentationModeActive) {
      return;
    }
    desktopState.closeAllWindows();
  }

  minimizeAllWindows() {
    if (systemState.state.isPresentationModeActive) {
      return;
    }
    desktopState.minimizeAllWindows();
  }
}

const desktopManager = new DesktopManager();
// eslint-disable-next-line import/no-default-export
export default desktopManager;
