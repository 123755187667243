export const random = {
  nextInt,
};

/**
 * @param min Inclusive min random int
 * @param max Inclusive max random int
 */
function nextInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
