import { useMemo } from "react";
import "./Img.scss";
import { combineClassNames } from "core/utils";

interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  noSmoothing?: boolean;
  initialSize?: boolean;
  base64?: string;
  base64ImageType?: string;
}

export function Img(props: ImgProps) {
  const { base64ImageType, base64, src: ignoredSrc, className, noSmoothing, initialSize, ...restProps } = props;
  const cls = combineClassNames(
    "managed-image",
    className,
    noSmoothing ? "no-smoothing" : undefined,
    initialSize && "initial-size"
  );
  const src = useImageSrc(props);

  return <img src={src} className={cls} {...restProps} />;
}

function useImageSrc(props: ImgProps) {
  const { src, base64, base64ImageType } = props;

  return useMemo(() => {
    if (src) return src;

    if (base64) {
      const imgType = base64ImageType || "image/jpeg";
      return `data:${imgType};base64,${base64}`;
    }

    return undefined;
  }, [src, base64, base64ImageType]);
}
