import { systemState, usePresentationModeActive } from "core/global";
import { useLocalStorageMultiTabUserInactivity } from "core/utils/userInactivityHook";
import securityService from "./securityService";
import { tx } from "core/intl";

export function AutoLogoutDueToInactivityGuard() {
  const autoLogoutMinutes = systemState.useGlobalState(gs => gs.config?.autoLogoutMinutes);
  const isPresentationModeActive = usePresentationModeActive();
  const autoLogoutSeconds = isPresentationModeActive ? 0 : (autoLogoutMinutes || 0) * 60;

  useLocalStorageMultiTabUserInactivity(autoLogoutSeconds, logoutDueToInactivity);

  return null;
}

function logoutDueToInactivity() {
  systemState.setLastLogoutReason(tx("Wylogowano automatycznie z powodu bezczynności"));
  securityService.logout();
}
