import { userDesktopStateService } from "apinet";
import desktopManager from "core/desktop/desktopManager";
import { DesktopSerializedState, desktopSerializedState } from "core/desktop/desktopSave/DesktopSerializedState";
import { usePresentationModeActive } from "core/global";
import { useDataLoader } from "core/utils";
import { useLocalStorageMultiTabUserInactivity } from "core/utils/userInactivityHook";

export function PresentationModeResetterDueToInactivity() {
  const isPresentationModeActive = usePresentationModeActive();

  return isPresentationModeActive ? <PresentationModeResetterInternal /> : null;
}

function PresentationModeResetterInternal() {
  const [desktopStateToRestore, ,] = useDataLoader(async () => {
    const desktops = await userDesktopStateService.getMetadataList();
    const autoRestore = desktops.find(x => x.restoreAfterLogin);
    if (!autoRestore) return undefined;

    const savedDesktopToRestore = await userDesktopStateService.getById({ id: autoRestore.id });
    const state = JSON.parse(savedDesktopToRestore.state) as DesktopSerializedState;
    return state;
  });

  useLocalStorageMultiTabUserInactivity(30, () => {
    if (desktopStateToRestore) {
      desktopManager.closeAllWindows(true);
      desktopSerializedState.restoreState(desktopStateToRestore);
    }
  });

  return null;
}
