import { HubConnectionState } from "@microsoft/signalr";
import { WebClientErrorReport, WebClientMetadataReport } from "apinet/models";
import { ActionLogEvent } from "apinet/models";
import { BaseEvent } from "common/eventBusTypes";
import { eventBus } from "core/events";
import { Hub } from "./Hub";

interface WsHubClientOperations {
  ServerTime(serverTime: number): void;
  PushEvent(ev: BaseEvent): void;
  Disconnect(): void;
}

interface WsHubServerOperations {
  LogAction(dto: ActionLogEvent): void;
  ReportClientMetadata(dto: WebClientMetadataReport): void;
  ReportClientError(dto: WebClientErrorReport): void;
}

export const wsHub = new Hub<WsHubClientOperations, WsHubServerOperations>("api/wshub");

wsHub.on("PushEvent", ev => {
  // console.log("WsHubEvent", ev);
  eventBus.publish(ev);
});

wsHub.on("Disconnect", () => {
  wsHub.stop();
  window.setTimeout(() => wsHub.start(), 2000);
})

export const wsHubHelper = {
  tryReportClientMetdata,
  tryReportErrorClient,
  formatError,
};

function formatError(error: any) {
  if (error instanceof Error) {
    return `${error.message}\n${error.stack}`;
  }

  return `${error}`;
}

async function tryReportClientMetdata(dto: WebClientMetadataReport) {
  if (wsHub.connection.state !== HubConnectionState.Connected) {
    return;
  }

  try {
    await wsHub.invoke("ReportClientMetadata", dto);
  } catch (error) {
    console.error(error);
  }
}

async function tryReportErrorClient(dto: WebClientErrorReport) {
  if (wsHub.connection.state !== HubConnectionState.Connected) {
    return;
  }

  try {
    await wsHub.invoke("ReportClientError", dto);
  } catch (error) {
    console.error(error);
  }
}
