import { MultiTokenFilteringMode, multiTokenFiltering } from "./mutlitokenFiltering";
import { ItemFilterPredicate, ItemFiltering } from "./selectUtils";

interface TokenMatcher {
  everyTokenMatch(searchValue: string | undefined): boolean;
}

const alwaysTokenMatcher: TokenMatcher = {
  everyTokenMatch: () => true,
};

export function tokenMatcherParse(searchToken: string): TokenMatcher {
  if (!searchToken) return alwaysTokenMatcher;

  const tokens = searchToken
    .toLowerCase()
    .split(" ")
    .map(x => x.trim())
    .filter(Boolean);

  return {
    everyTokenMatch(searchValue: string | undefined) {
      if (!searchValue) return false;
      const searchValueLower = searchValue.toLowerCase();
      return tokens.every(t => searchValueLower.includes(t));
    },
  };
}

export function createFilterPredicateForItem<TItem>(
  filteringMode: ItemFiltering<TItem>,
  searchValueGetter: (item: TItem) => string,
  searchText: string
): ItemFilterPredicate<TItem> {
  if (typeof filteringMode === "function") return filteringMode;

  switch (filteringMode) {
    case "multitokenIncludes":
      return filteringUsingMultitokenPredicate("includes", searchValueGetter, searchText);

    case "multitokenStartsWith":
      return filteringUsingMultitokenPredicate("startsWith", searchValueGetter, searchText);

    default:
      return simpleIncludesFiltering(searchValueGetter, searchText);
  }
}

function alwaysReturnsTrue() {
  return true;
}

function simpleIncludesFiltering<TItem>(
  searchValueGetter: (item: TItem) => string,
  searchText: string
): ItemFilterPredicate<TItem> {
  if (!searchText) return alwaysReturnsTrue;

  const searchTextLower = searchText.toLowerCase();

  return item => {
    const searchValue = searchValueGetter(item).toLowerCase();
    return searchValue.includes(searchTextLower);
  };
}

function filteringUsingMultitokenPredicate<TItem>(
  multitokenFilteringMode: MultiTokenFilteringMode,
  searchValueGetter: (item: TItem) => string,
  searchText: string
): ItemFilterPredicate<TItem> {
  const predicate = multiTokenFiltering.getPredicate(multitokenFilteringMode, searchText);

  return item => {
    const searchValue = searchValueGetter(item).toLowerCase();
    return predicate(searchValue);
  };
}
