// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { ClientExposedConfig, ClientWelcomeConfig } from './models';

const staticRequestHeaders = {
  'x-api-v2': '1'
}

const paths = {
  getClientExposedConfig: 'api/ClientConfig/GetClientExposedConfig',
  getLogoSvg: 'api/ClientConfig/GetLogoSvg',
  favicon: 'favicon',
  welcomeImage: 'api/ClientConfig/WelcomeImage',
  welcomeConfig: 'api/ClientConfig/WelcomeConfig',
};

function getClientExposedConfig(): Promise<ClientExposedConfig> {
  return axios.request<ClientExposedConfig>({
    url: paths.getClientExposedConfig,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

interface GetLogoSvgParams {
  encoded?: boolean;
  buttonVariant?: boolean;
}

function getLogoSvg(params: GetLogoSvgParams): Promise<string> {
  return axios.request<string>({
    url: paths.getLogoSvg,
    method: 'get',
    params,
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function favicon(): Promise<File> {
  return axios.request<Blob>({
    url: paths.favicon,
    method: 'get',
    responseType: 'blob',
    headers: staticRequestHeaders,
  }).then(convertBlobResponseToFile);
}

function welcomeImage(): Promise<File> {
  return axios.request<Blob>({
    url: paths.welcomeImage,
    method: 'get',
    responseType: 'blob',
    headers: staticRequestHeaders,
  }).then(convertBlobResponseToFile);
}

function welcomeConfig(): Promise<ClientWelcomeConfig> {
  return axios.request<ClientWelcomeConfig>({
    url: paths.welcomeConfig,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function convertBlobResponseToFile(res: AxiosResponse<Blob>): File {
  const contentDispositionHeader = res.headers['content-disposition'] || '';
  const filenameMatch = /filename="?([^;,"]+)/.exec(contentDispositionHeader);
  const filename = filenameMatch ? filenameMatch[1] : '';
  return new File([res.data], filename);
}

export const clientConfigService = {
  paths,
  getClientExposedConfig,
  getLogoSvg,
  favicon,
  welcomeImage,
  welcomeConfig,
};
