import { WebClientMetadataReport } from "apinet/models";
import { systemState } from "core/global";
import { desktopState } from "core/global/desktopState";
import { useDelayedEffect, useInterval } from "core/utils";
import { wsHubHelper } from "./wsHub";
import { bundledAppVersionInfo } from "core/bundledAppVersionInfo";

export function WebClientMedataReporter() {
  useDelayedEffect(reportMetadata, 2000);
  useInterval(reportMetadata, 15000);

  return null;
}

function reportMetadata() {
  const activeWindow = desktopState.state.windows.filter(x => x.isActive)[0];
  const dto: WebClientMetadataReport = {
    appVersion: bundledAppVersionInfo.fullVersion || "?",
    nativeWindowMode: systemState.state.nativeWindowMode,
    windows: desktopState.state.windows.map(x => ({
      title: x.title,
    })),
    activeWindow: activeWindow && { title: activeWindow.title },
  };

  wsHubHelper.tryReportClientMetdata(dto);
}
