import { useEffect, useRef, useState } from "react";

export function useLocalStorageMultiTabUserInactivity(
  inactivitySecondsThershold: number,
  onInactivityDetectedCallback: () => any
) {
  const inactivityCallbackRef = useRef(onInactivityDetectedCallback);
  inactivityCallbackRef.current = onInactivityDetectedCallback;
  const [estimatedLogoutDate, setEstimatedLogoutDate] = useState<Date>();

  useEffect(() => {
    if (!inactivitySecondsThershold) {
      setEstimatedLogoutDate(undefined);
      return;
    }

    const hookInstance = new DistributedUserInactivityHook(inactivitySecondsThershold);
    hookInstance.addInactivityCallback(onInactivityCallback);

    return () => {
      hookInstance.destroy();
    };

    function onInactivityCallback() {
      inactivityCallbackRef.current();
    }
  }, [inactivitySecondsThershold]);

  return estimatedLogoutDate;
}

type InactivityCallback = () => any;

class DistributedUserInactivityHook {
  lastDistributedActivityLocalStorageKey = "lastUserMultiTabActivity";
  lastActivityCheckSecondsTolerance = 2;

  lastActivity = this.getCurrentUnixTime();
  lastActivityStored = 0;

  private callbacks: InactivityCallback[] = [];
  private inactivityTimeoutHandle: number | undefined;

  constructor(private inactivitySecondsThershold: number) {
    this.onLocalActivityDetected = this.onLocalActivityDetected.bind(this);
    this.checkForInactivityTime = this.checkForInactivityTime.bind(this);

    window.addEventListener("mousemove", this.onLocalActivityDetected);
    window.addEventListener("click", this.onLocalActivityDetected);
    window.addEventListener("keypress", this.onLocalActivityDetected);

    this.onLocalActivityDetected();
  }

  addInactivityCallback(cb: InactivityCallback) {
    this.callbacks.push(cb);
  }

  removeInactivityCallback(cb: InactivityCallback) {
    const idx = this.callbacks.indexOf(cb);
    if (idx >= 0) {
      this.callbacks.splice(idx, 1);
    }
  }

  destroy() {
    window.removeEventListener("mousemove", this.onLocalActivityDetected);
    window.removeEventListener("click", this.onLocalActivityDetected);
    window.removeEventListener("keypress", this.onLocalActivityDetected);
    this.cancelCurrentTimeout();
    this.callbacks = [];
  }

  private checkForInactivityTime() {
    const distributedLastActivity = this.getLastActivityFromStorageOrCached();
    const now = this.getCurrentUnixTime();
    const lastActivitySecondsAgo = now - distributedLastActivity;

    if (lastActivitySecondsAgo < this.inactivitySecondsThershold) {
      const newSecondsWaitTime =
        this.inactivitySecondsThershold - lastActivitySecondsAgo + this.lastActivityCheckSecondsTolerance;
      this.setupTimeoutAfter(newSecondsWaitTime);
    } else {
      this.invokeCallbacks();
    }
  }

  private onLocalActivityDetected() {
    this.updateLastActivity();
    this.setupTimeoutAfter((this.inactivitySecondsThershold + this.lastActivityCheckSecondsTolerance) * 1000);
  }

  private setupTimeoutAfter(ms: number) {
    this.cancelCurrentTimeout();
    this.inactivityTimeoutHandle = window.setTimeout(this.checkForInactivityTime, ms);
  }

  private cancelCurrentTimeout() {
    if (this.inactivityTimeoutHandle !== undefined) {
      window.clearTimeout(this.inactivityTimeoutHandle);
      this.inactivityTimeoutHandle = undefined;
    }
  }

  private invokeCallbacks() {
    for (let cb of this.callbacks) {
      try {
        cb();
      } catch (err) {
        console.error(err);
      }
    }
  }

  private updateLastActivity() {
    this.lastActivity = this.getCurrentUnixTime();

    const storedDiff = this.lastActivity - this.lastActivityStored;
    if (storedDiff > 2) {
      this.lastActivityStored = this.lastActivity;
      window.localStorage.setItem(this.lastDistributedActivityLocalStorageKey, this.lastActivity.toString());
    }
  }

  private getLastActivityFromStorageOrCached() {
    const lastActivityFromStorageValue = Number(
      window.localStorage.getItem(this.lastDistributedActivityLocalStorageKey)
    );
    if (isNaN(lastActivityFromStorageValue)) {
      return this.lastActivity;
    }

    return this.lastActivity > lastActivityFromStorageValue ? this.lastActivity : lastActivityFromStorageValue;
  }

  private getCurrentUnixTime() {
    return Math.floor(Date.now() / 1000);
  }
}
