import { ItemDisplayFn, selectUtils } from "core/utils";
import { Checkbox } from "./Checkbox";

interface CheckboxListProps<TItem> {
  inline?: boolean;
  value: TItem[] | undefined;
  items: TItem[];
  onChange: (value: TItem[]) => any;
  display?: keyof TItem | ItemDisplayFn<TItem>;
  disabled?: boolean;
}

export function CheckboxList<TItem>(props: CheckboxListProps<TItem>) {
  const { value, items, onChange, display, inline, disabled } = props;
  const selectedItems = value || [];

  const toggle = (item: TItem) => {
    const enabled = selectedItems.includes(item);
    onChange(enabled ? selectedItems.filter(x => x !== item) : selectedItems.concat(item));
  };

  return (
    <>
      {items.map((item, i) => (
        <Checkbox
          key={i}
          value={selectedItems.includes(item)}
          onChange={() => toggle(item)}
          inline={inline}
          disabled={disabled}
        >
          {selectUtils.formatItemDisplay(item, display)}
        </Checkbox>
      ))}
    </>
  );
}
