import React from "react";

interface SelectNativeProps<TItem> {
  items: TItem[];
  value: string | undefined;
  getKey: (item: TItem) => string;
  getLabel: (item: TItem) => string;
  onSelect: (item?: TItem) => any;
  emptyOption?: string;
  emptyOptionValue?: string | number;
  style?: React.CSSProperties;
}

export class SelectNative<TItem> extends React.PureComponent<SelectNativeProps<TItem>> {
  constructor(props: SelectNativeProps<TItem>) {
    super(props);

    this.onSelectChange = this.onSelectChange.bind(this);
  }

  render() {
    const { value, emptyOption, emptyOptionValue, items } = this.props;
    const style: React.CSSProperties = Object.assign({ width: "100%" }, this.props.style);

    return (
      <select onChange={this.onSelectChange} value={value || ""} style={style}>
        {emptyOption !== undefined && <option value={emptyOptionValue || undefined}>{emptyOption}</option>}
        {items.map(x => {
          const key = this.props.getKey(x);
          const label = this.props.getLabel(x);
          return (
            <option key={key} value={key}>
              {label}
            </option>
          );
        })}
      </select>
    );
  }

  onSelectChange(ev: React.ChangeEvent<HTMLSelectElement>) {
    const value = ev.target.value;
    if (!value) {
      this.props.onSelect(undefined);
    } else {
      const item = this.props.items.filter(x => this.props.getKey(x) === value)[0];
      this.props.onSelect(item);
    }
  }
}
