import { defineWindowDetails, useInitWindowSize } from "core/desktop";
import { WindowLazyContent } from "core/desktop/WindowLazyContent";
import { tx } from "core/intl";
import { lazy } from "react";

type ItcListWindowProps = React.ComponentProps<typeof ItcListWindowContent>;

const ItcListWindowContent = lazy(() =>
  import("./ItcListWindowContent").then(m => ({ default: m.ItcListWindowContent }))
);

defineWindowDetails(ItcListWindow, {
  icon: "fas fa-traffic-light",
  title: tx("Controllers list"),
  isAvailable: ss => ss.privs.itcView,
});

export function ItcListWindow(props: ItcListWindowProps) {
  useInitWindowSize(400, 700);

  return (
    <WindowLazyContent>
      <ItcListWindowContent {...props} />
    </WindowLazyContent>
  );
}
