import { convertErrorToUserMessage } from "api/apiUtils";
import axios from "axios";
import { eventBus } from "../core/events/eventBus";

axios.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    if (err.config.url !== "api/auth/info" && err.response && err.response.status === 401) {
      eventBus.publish({ code: "apiResponse401", occuredAt: new Date().toISOString() });
    }

    return Promise.reject(convertErrorToUserMessage(err));
  }
);
