import { ValidationObjectData } from "core/validation";
import React from "react";
import { convertErrorToUserMessage } from "../../api/apiUtils";

interface PendingOperationProps {
  promise: Promise<any> | undefined;
  children: (arg: PendingOperationState) => React.ReactNode;
}

const emptyValidationObjectData: ValidationObjectData = {};

export interface PendingOperationState {
  pending: boolean;
  success: boolean;
  error: boolean;
  operationCounter: number;
  errorMessage: string;
  technicalError: string;
  validation: ValidationObjectData;
  rawResult?: any;
  rawError?: any;
}

export class PendingOperation extends React.Component<PendingOperationProps, PendingOperationState> {
  destroyed = false;

  constructor(props: PendingOperationProps) {
    super(props);

    this.state = {
      pending: false,
      success: false,
      error: false,
      operationCounter: 0,
      errorMessage: "",
      technicalError: "",
      validation: emptyValidationObjectData,
      rawError: undefined,
      rawResult: undefined,
    };
  }

  render() {
    return this.props.children(this.state);
  }

  componentDidUpdate(prevProps: PendingOperationProps) {
    if (prevProps.promise !== this.props.promise) {
      this.handle(this.props.promise);
    }
  }

  componentWillUnmount() {
    this.destroyed = true;
  }

  handle(operationPromise: Promise<any> | undefined) {
    this.clear();
    if (!operationPromise) return;

    this.setState(prev => ({
      pending: true,
      operationCounter: prev.operationCounter + 1,
    }));

    operationPromise
      .then(res => {
        if (this.props.promise !== operationPromise) return;
        if (this.destroyed) return;

        this.setState({
          pending: false,
          success: true,
          rawResult: res,
        });
      })
      .catch(ex => {
        if (this.props.promise !== operationPromise) return;
        if (this.destroyed) return;

        const err = convertErrorToUserMessage(ex);

        this.setState({
          pending: false,
          error: true,
          errorMessage: err.message || "",
          technicalError: err.originalMessage || "",
          validation: err.validationObject || emptyValidationObjectData,
          rawError: ex,
        });
      });
  }

  clear() {
    this.setState({
      pending: false,
      success: false,
      error: false,
      errorMessage: "",
      technicalError: "",
      validation: emptyValidationObjectData,
      rawResult: undefined,
      rawError: undefined,
    });
  }
}
