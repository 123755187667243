import * as signalR from "@microsoft/signalr";
import { systemState } from "core/global";
import { PickByType } from "core/utils";
import { useEffect, useRef } from "react";

export interface HubOperationSubscription {
  unsubscribe(): void;
}

type OperationType = (...args: any) => any;

export class Hub<TClientOperations = {}, TServerOperations = {}> {
  connection: signalR.HubConnection;
  enabled = false;
  reconnectCount = 0;

  constructor(public url: string) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        transport: signalR.HttpTransportType.WebSockets,
        skipNegotiation: true,
        withCredentials: true,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: ctx => {
          this.reconnectCount = ctx.previousRetryCount + 1;
          this.updateConnectionProblemStatus();
          return 2000;
        },
      })
      .build();
    this.connection.onreconnected(() => {
      this.reconnectCount = 0;
      this.updateConnectionProblemStatus();
    });
  }

  start() {
    this.enabled = true;
    if (this.connection.state !== signalR.HubConnectionState.Disconnected) return;

    this.connection
      .start()
      .then(() => {
        console.log("WsHub connected");
        this.reconnectCount = 0;
        this.updateConnectionProblemStatus();
      })
      .catch(err => {
        if (!this.enabled) return;

        console.log("SignalR connection error, retry in 2", err);
        setTimeout(() => this.start(), 2000);
        this.reconnectCount++;
        this.updateConnectionProblemStatus();
      });
  }

  stop() {
    this.enabled = false;
    this.connection.stop();

    this.reconnectCount = 0;
    this.updateConnectionProblemStatus();
  }

  send<OpCode extends keyof PickByType<TServerOperations, OperationType> & string>(
    operation: OpCode,
    ...args: TServerOperations[OpCode] extends OperationType ? Parameters<TServerOperations[OpCode]> : never
  ): Promise<void> {
    return this.connection.send(operation, ...(args as any));
  }

  invoke<OpCode extends keyof PickByType<TServerOperations, OperationType> & string>(
    operation: OpCode,
    ...args: TServerOperations[OpCode] extends OperationType ? Parameters<TServerOperations[OpCode]> : never
  ): Promise<TServerOperations[OpCode] extends OperationType ? ReturnType<TServerOperations[OpCode]> : never> {
    return this.connection.invoke(operation, ...(args as any));
  }

  on<OpCode extends keyof TClientOperations & string>(
    operation: OpCode,
    handler: TClientOperations[OpCode]
  ): HubOperationSubscription {
    this.connection.on(operation, handler as any);

    return {
      unsubscribe: () => this.connection.off(operation, handler as any),
    };
  }

  useOperation<OpCode extends keyof TClientOperations & string>(operation: OpCode, handler: TClientOperations[OpCode]) {
    // eslint-disable-next-line
    useHubOperation(this as any, operation, handler);
  }

  private updateConnectionProblemStatus() {
    systemState.setWsHubConnectionProblem(this.reconnectCount >= 3);
  }
}

export function useHubOperation<T, OpCode extends keyof T & string>(
  hub: Hub<T>,
  operation: OpCode,
  handler: T[OpCode]
) {
  const handlerRef = useRef<any>(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    function handleFn() {
      handlerRef.current.apply(null, arguments);
    }
    const sub = hub.on(operation, handleFn as any);

    return () => {
      sub.unsubscribe();
    };
  }, [hub, operation]);
}
