import { Dropdown } from "core/content";
import { ClickableText } from "core/forms";
import { useCurrentLangCodeUpperCase, useLanguagesToSelect } from "core/intl";
import { Icon, usePendingOperation } from "core/utils";
import i18next from "i18next";

export function LoginPageLanguageSelector() {
  const currentLang = useCurrentLangCodeUpperCase();
  const languages = useLanguagesToSelect();
  const changeLangOp = usePendingOperation();
  const selectLang = (langCode: string) => {
    changeLangOp.handle(i18next.changeLanguage(langCode));
  };

  if (!languages || !languages.length) {
    return null;
  }

  return (
    <Dropdown items={languages || []} onSelect={x => selectLang(x.code)} display="nativeName" placement="top-end">
      <ClickableText overline>
        <Icon symbol="globe" spin={changeLangOp.state.pending} />
        {currentLang}
      </ClickableText>
    </Dropdown>
  );
}
