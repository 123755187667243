import { BaseEvent, EntityChangedEvent, EntityStateChangedEvent, EntityType } from "common/eventBusTypes";
import { useDebounce } from "core/utils/debounceHooks";
import { random } from "core/utils/random";
import { useState } from "react";
import { BusEventHandler, eventBus } from "./eventBus";

export function useEntityChanged(entityType: EntityType, handler: BusEventHandler<EntityChangedEvent>) {
  eventBus.useEvent<EntityChangedEvent>("entityChanged", ev => {
    if (ev.entityType === entityType) {
      handler(ev);
    }
  });
}

export function useEntityStateChanged(entityType: EntityType, handler: BusEventHandler<EntityStateChangedEvent>) {
  eventBus.useEvent<EntityStateChangedEvent>("entityStateChanged", ev => {
    if (ev.entityType === entityType) {
      handler(ev);
    }
  });
}

export function useEntityChangedDebounced(
  entityType: EntityType,
  handler: BusEventHandler<EntityChangedEvent>,
  delay: number
) {
  const handlerDebounced = useDebounce(handler, delay);
  useEntityChanged(entityType, handlerDebounced);
}

export function useEntityStateChangedDebounced(
  entityType: EntityType,
  handler: BusEventHandler<EntityStateChangedEvent>,
  delay: number
) {
  const handlerDebounced = useDebounce(handler, delay);
  useEntityStateChanged(entityType, handlerDebounced);
}

export function useEntityWithIdChanged(
  entityType: EntityType,
  entityId: number | undefined,
  handler: BusEventHandler<EntityChangedEvent>
) {
  eventBus.useEvent<EntityChangedEvent>("entityChanged", ev => {
    if (ev.entityType === entityType && ev.entityId === entityId) {
      handler(ev);
    }
  });
}

export function useEntityWithIdStateChanged(
  entityType: EntityType,
  entityId: number | undefined,
  handler: BusEventHandler<EntityStateChangedEvent>
) {
  eventBus.useEvent<EntityStateChangedEvent>("entityStateChanged", ev => {
    if (ev.entityType === entityType && ev.entityId === entityId) {
      handler(ev);
    }
  });
}


export function useEventDebounced<T extends BaseEvent>(code: T["code"], handler: BusEventHandler<T>, delay: number) {
  const handlerDebounced = useDebounce(handler, delay);
  eventBus.useEvent<T>(code, handlerDebounced);
}

export function useEventRandomDebounced<T extends BaseEvent>(
  code: T["code"],
  handler: BusEventHandler<T>,
  delayMin: number,
  delayMax: number
) {
  const [delay] = useState(() => random.nextInt(delayMin, delayMax));

  const handlerDebounced = useDebounce(handler, delay);
  eventBus.useEvent<T>(code, handlerDebounced);
}
