const KeyToKeyCode = {
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
  ctrl: 17,
  alt: 18,
  escape: 27,
  space: 32,
  pageup: 33,
  pagedown: 34,
  end: 35,
  home: 36,
  leftarrow: 37,
  uparrow: 38,
  rightarrow: 39,
  downarrow: 40,
  tilde: 192,
  0: 48,
  1: 49,
  2: 50,
  3: 51,
  4: 52,
  5: 53,
  6: 54,
  7: 55,
  8: 56,
  9: 57,
  a: 65,
  b: 66,
  c: 67,
  d: 68,
  e: 69,
  f: 70,
  g: 71,
  h: 72,
  i: 73,
  j: 74,
  k: 75,
  l: 76,
  m: 77,
  n: 78,
  o: 79,
  p: 80,
  q: 81,
  r: 82,
  s: 83,
  t: 84,
  u: 85,
  v: 86,
  w: 87,
  x: 88,
  y: 89,
  z: 90,
  semicolon: 186,
  equal: 187,
  comma: 188,
  dash: 189,
  period: 190,
  slash: 191,
  backslash: 220,
  openbracket: 219,
  closebracket: 221,
  singlequote: 222
};

let KeyCodeToKey: { [key: number]: string } = {};

const tmp = KeyToKeyCode as any;
for (let key in tmp) {
  if (tmp.hasOwnProperty(key)) {
      KeyCodeToKey[tmp[key]] = key;
  }
}

export const KeyCodes = KeyToKeyCode;
export const KeyCodeToKeyMap = KeyCodeToKey;