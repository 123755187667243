import React from "react";
import { formatElapsedMs, formatElapsedSeconds } from "./utils";

interface ElapsedHighAccuracyTimeProps {
  elapsedMs: number;
}

export class ElapsedHighAccuracyTime extends React.PureComponent<ElapsedHighAccuracyTimeProps> {
  render() {
    return <span>{formatElapsedMs(this.props.elapsedMs)}</span>;
  }
}
