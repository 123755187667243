import React, { useMemo, useRef } from "react";
import { toClassNames, typedMemo } from "./reactHelpers";
import "./MonthStatView.scss";
import { formatDate, formatUnixDate, monthNames } from "./dateUtils";
import { Table } from "../layout/Table";
import { Th } from "core/layout";

interface MonthStatViewProps<TItem> {
  monthData: MonthData<TItem>;
  selected?: string;
  onSelect: (d: string) => any;
  monthNavigatorDate: Date;
  onMonthNavigatorDateChange: (value: Date) => any;
}

interface MonthData<TItem> {
  weeks: DayInWeek<TItem>[][];
  items: TItem[];
  filteredItems?: TItem[];
  daysByFullDateLabel: Record<string, DayInWeek<TItem>>;
}

export interface DayInWeek<TItem> {
  currentMonth: boolean;
  items: TItem[];
  filteredItems?: TItem[];
  date: Date;
  fullDateLabel: string;
  label: number;
  roundedDate: string;
}

export const MonthStatView = typedMemo(function MonthStatView<TItem>(props: MonthStatViewProps<TItem>) {
  const { monthData, selected, onSelect, monthNavigatorDate, onMonthNavigatorDateChange } = props;
  const { weeks, items, filteredItems } = monthData;

  const year = monthNavigatorDate.getFullYear();
  const month = monthNavigatorDate.getMonth();
  const monthName = monthNames[month];

  const changeMonth = (diff: number) => {
    const d = new Date(monthNavigatorDate.getFullYear(), monthNavigatorDate.getMonth(), 1);
    d.setMonth(d.getMonth() + diff);
    onMonthNavigatorDateChange(d);
  };

  return (
    <Table className="month-stat-view">
      <thead>
        <tr>
          <th onClick={() => changeMonth(-1)} className="month-navigator-header-button">
            <i className="fas fa-chevron-left" />
          </th>
          <th colSpan={5}>
            {monthName}&nbsp;{year}
          </th>
          <th onClick={() => changeMonth(1)}  className="month-navigator-header-button">
            <i className="fas fa-chevron-right"  />
          </th>
        </tr>
        <tr>
          <Th>Pn</Th>
          <Th>Wt</Th>
          <Th>Śr</Th>
          <Th>Czw</Th>
          <Th>Pt</Th>
          <Th>So</Th>
          <Th>Nd</Th>
        </tr>
      </thead>
      <tbody>
        {weeks.map((week, i) => (
          <tr key={i}>
            {week.map((d, j) => (
              <td
                key={j}
                className={toClassNames({
                  selected: d.fullDateLabel === selected,
                })}
                onClick={() => onSelect(d.fullDateLabel)}
              >
                {renderCellContent(d)}
              </td>
            ))}
          </tr>
        ))}
        <tr>
          <td colSpan={7}>
            <div>
              Suma wszystkich: <span className="total-count">{items?.length}</span>
            </div>
            {filteredItems && (
              <div>
                Suma spełniających filtr: <span className="total-count">{filteredItems.length}</span>
              </div>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
});

function renderCellContent(d: DayInWeek<any>) {
  if (!d.currentMonth) return null;

  const mainCount = d.filteredItems ? d.filteredItems.length : d.items.length;
  const cls = mainCount ? "count" : undefined;

  return (
    <>
      <span className="date-label">{d.label}</span>
      <span className={cls}>{mainCount}</span>
      {d.filteredItems && <span className="not-filtered-count">/{d.items.length}</span>}
    </>
  );
}

export function useMonthAggregatedData<TItem>(
  year: number,
  month: number,
  items: TItem[],
  filteredItems: TItem[] | undefined,
  itemUnixDateGetter: (item: TItem) => number
): MonthData<TItem> {
  const itemUnixDateGetterRef = useRef(itemUnixDateGetter);
  itemUnixDateGetterRef.current = itemUnixDateGetter;

  return useMemo(() => {
    return generateMonthData(year, month, items || [], filteredItems, itemUnixDateGetterRef.current);
  }, [year, month, items, filteredItems]);
}

function generateMonthData<TItem>(
  year: number,
  month: number,
  items: TItem[],
  filteredItems: TItem[] | undefined,
  itemUnixDateGetter: (item: TItem) => number
): MonthData<TItem> {
  const roundedDateMap: Record<string, DayInWeek<TItem>> = {};

  const firstDayOfWeek = 1;
  const date = new Date(year, month, 10);
  const d = new Date(year, month, 1);
  if (d.getDay() === 0 && d.getDay() !== firstDayOfWeek) {
    d.setDate(d.getDate() - 7 + firstDayOfWeek);
  } else {
    d.setDate(d.getDate() - d.getDay() + firstDayOfWeek);
  }

  const weeks: DayInWeek<TItem>[][] = [];
  while ((d.getMonth() <= month && d.getFullYear() <= year) || d.getTime() < date.getTime()) {
    const week: DayInWeek<TItem>[] = [];

    for (var i = 0; i < 7; i++) {
      const roundedDate = formatDate(d, "yyyy-MM-dd");
      const day: DayInWeek<TItem> = {
        date: new Date(d),
        fullDateLabel: roundedDate,
        label: d.getDate(),
        currentMonth: date.getMonth() === d.getMonth() && date.getFullYear() === d.getFullYear(),
        items: [],
        filteredItems: filteredItems ? [] : undefined,
        roundedDate,
      };
      week.push(day);
      roundedDateMap[roundedDate] = day;
      d.setDate(d.getDate() + 1);
    }

    weeks.push(week);
  }

  assignItemsToDays(roundedDateMap, items, filteredItems, itemUnixDateGetter);

  return {
    weeks,
    items,
    filteredItems,
    daysByFullDateLabel: roundedDateMap,
  };
}

function assignItemsToDays<TItem>(
  roundedDateMap: Record<string, DayInWeek<TItem>>,
  items: TItem[],
  filteredItems: TItem[] | undefined,
  itemUnixDateGetter: (item: TItem) => number
) {
  for (let item of items) {
    const unixDate = itemUnixDateGetter(item);
    const formatted = formatUnixDate(unixDate, "yyyy-MM-dd");
    const day = roundedDateMap[formatted];
    if (day) {
      day.items.push(item);
    }
  }

  if (filteredItems) {
    for (let item of filteredItems) {
      const unixDate = itemUnixDateGetter(item);
      const formatted = formatUnixDate(unixDate, "yyyy-MM-dd");
      const day = roundedDateMap[formatted];
      if (day && day.filteredItems) {
        day.filteredItems.push(item);
      }
    }
  }
}
