import { ValidationContext } from "core/validation";
import { useContext, useMemo } from "react";
import { FormItem } from "../FormItem";

export function FormItemWithUnhandledValidationErrors() {
  const ctx = useContext(ValidationContext);
  const unhanldedValidations = useMemo(() => {
    const handledFieldsSet = new Set(Object.keys(ctx.handledFields));
    return Object.keys(ctx.object)
      .filter(field => !handledFieldsSet.has(field))
      .map(field => ctx.object[field])
      .filter(Boolean);
  }, [ctx]);

  if (!unhanldedValidations.length) {
    return null;
  }

  return (
    <FormItem>
      {unhanldedValidations.map((x, i) => (
        <div key={i} className="error-field-annotation">
          {x.aggregatedMessage}
        </div>
      ))}
    </FormItem>
  );
}
