import { contextHelpService } from "apinet";
import { ContextHelpMetadata } from "apinet/models";
import desktopManager from "core/desktop/desktopManager";
import { ContextHelpWindow } from "./ContextHelpWindow";

let helpMetadataCache: Record<string, Promise<ContextHelpMetadata>> = {};

export const contextHelp = {
  openHelp,
  openHelpByCode,
  getMetadata,
  clearMetadataCache,
};

async function getMetadata(code: string): Promise<ContextHelpMetadata> {
  if (!helpMetadataCache[code]) {
    helpMetadataCache[code] = contextHelpService.getMetadataForCode({ code }).catch(err => {
      return {
        code,
        hasContent: false,
      };
    });
  }

  return await helpMetadataCache[code];
}

function clearMetadataCache() {
  helpMetadataCache = {};
}

async function openHelpByCode(code: string): Promise<void> {
  const metadata = await getMetadata(code);
  await openHelp(metadata);
}

async function openHelp(metadata: ContextHelpMetadata): Promise<void> {
  if (!metadata.hasContent || !metadata.contextHelpArticleId) return;

  if (metadata.url) {
    window.open(metadata.url, "_blank", "noopener,noreferrer");
    return;
  }

  desktopManager.openWindow({
    component: ContextHelpWindow,
    props: {
      articleId: metadata.contextHelpArticleId,
    },
  });
}
