import { TimepickerInput } from "core/forms";
import React, { useContext } from "react";
import { FormContext } from "./FormContext";

type TimepickerInputProps = React.ComponentProps<typeof TimepickerInput>;

interface TimeFieldProps extends Omit<TimepickerInputProps, "value" | "onChange"> {
  name: string;
  inputName?: string;
}

export const TimeField = React.memo(function TimeField(props: TimeFieldProps) {
  const form = useContext(FormContext);
  let { name, inputName, ...restAttrs } = props;
  const value = form.model[name];

  return (
    <TimepickerInput
      {...restAttrs}
      onChange={value => form.handleFieldChange(name, value)}
      value={value}
      name={inputName}
      disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
    />
  );
});
