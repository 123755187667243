import React, { useContext } from "react";
import { FormContext } from "./FormContext";
import { SwitchSlider } from "../SwitchSlider";

type SwitchSliderProps = React.ComponentProps<typeof SwitchSlider>;

interface SwitchSliderFieldProps extends Omit<SwitchSliderProps, "value" | "onChange"> {
  name: string;
}

export const SwitchSliderField = React.memo(function SwitchSliderField(
  props: SwitchSliderFieldProps
) {
  const form = useContext(FormContext);
  const { name, ...restAttrs } = props;
  const value = form.model[name];

  return (
    <SwitchSlider
      {...restAttrs}
      onChange={(value) => form.handleFieldChange(name, value)}
      value={value}
      disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
    />
  );
});
