import { combineClassNames } from "core/utils";
import React from "react";

interface FormDivProps {
  children?: React.ReactNode;
  className?: string;
}

export function FormDiv(props: FormDivProps) {
  const cls = combineClassNames("form", props.className);

  return <div className={cls}>{props.children}</div>;
}
