import { useRef, useEffect } from "react";

export function useIsDestroyed() {
  const destroyedRef = useRef(false);

  useEffect(() => {
    return () => {
      destroyedRef.current = true;
    };
  }, []);

  return destroyedRef.current;
}

export function useDelayedEffect(
  callback: () => any,
  delay: number,
  deps?: any[]
) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    const callEffect = () => callbackRef.current && callbackRef.current();
    const handle = window.setTimeout(callEffect, delay);
    return () => window.clearInterval(handle);
  }, [delay, ...(deps || [])]); // eslint-disable-line
}

export function useWindowEventListener<K extends keyof WindowEventMap>(
  type: K,
  listener: (ev: WindowEventMap[K]) => any
): void {
  const listenerRef = useRef(listener);
  listenerRef.current = listener;

  useEffect(() => {
    const internalListener = (ev: WindowEventMap[K]) => {
      listenerRef.current(ev);
    };

    window.addEventListener(type, internalListener);

    return () => {
      window.removeEventListener(type, internalListener);
    };
  }, [type]);
}
