import { useEffect, useState } from "react";
import securityService from "./securityService";
import { systemState } from "core/global/systemState";
import { Form, FormItem, TextField, PasswordField, SubmitButton } from "core/forms";
import { FeedbackMessage } from "core/content";
import { usePendingOperation } from "core/utils";
import { tx } from "core/intl";

interface LoginFormProps {
  login?: string;
  onLoginActionRequired: () => any;
}

interface Model {
  username: string;
  password: string;
}

export function LoginForm(props: LoginFormProps) {
  const [model, setModel] = useState<Model>({ username: props.login || "", password: "" });
  const [coolDownActive, setCoolDownActive] = useState(false);
  const op = usePendingOperation();

  useEffect(() => {
    if (!coolDownActive) return;

    const clearCoolDownHandle = window.setTimeout(() => setCoolDownActive(false), 500);
    return () => {
      window.clearTimeout(clearCoolDownHandle);
    };
  }, [coolDownActive]);

  const accept = () => {
    if (coolDownActive) return;
    if (op.syncPending) return;

    systemState.setLastLogoutReason(undefined);

    op.handle(
      securityService.login(model.username, model.password).then(res => {
        if (!res.success && res.requiredActions) {
          props.onLoginActionRequired();
        }

        setCoolDownActive(true);
      })
    );
  };

  return (
    <Form rootComponent="form" model={model} onChangeDispatch={setModel} onSubmit={accept} disabled={op.state.pending}>
      <FormItem label="Użytkownik">
        <TextField name="username" inputName="username" autoFocus={!props.login} placeholder="Nazwa użytkownika" />
      </FormItem>
      <FormItem label="Hasło">
        <PasswordField
          name="password"
          inputName="password"
          placeholder="Podaj hasło"
          autoFocus={Boolean(props.login)}
        />
      </FormItem>
      {op.state.error && (
        <FeedbackMessage kind="error">{op.state.errorMessage || tx("Błąd logowania")}</FeedbackMessage>
      )}
      <div className="text-right">
        <SubmitButton cta big disabled={op.state.pending || coolDownActive}>
          {op.state.pending ? tx("Logowanie...") : tx("Zaloguj")}
        </SubmitButton>
      </div>
    </Form>
  );
}
