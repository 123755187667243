import { WindowInstance } from "../desktop/Window";
import { GlobalState } from "react-gstate";
import { genericOrderCompareFn } from "core/utils";

export type WindowEdgeDetection =
  | "inactive"
  | "activeNone"
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "top-left"
  | "top-right"
  | "bottom-right"
  | "bottom-left";

export interface DesktopState {
  windows: WindowInstance[];
  currentEdgeDetection: WindowEdgeDetection;
  desktopWidth: number;
  desktopHeight: number;
}

class DesktopStateStore extends GlobalState<DesktopState> {
  addWindow(wnd: WindowInstance) {
    const windows = this.state.windows.slice();
    windows.push(wnd);
    this.setState({
      windows,
    });
  }

  activateWindow(wnd: WindowInstance) {
    if (wnd.isActive) return this.state;

    wnd.minimized = false;
    wnd.zIndex = -1;
    findAndLowerWindowWithIndex(this.state, this.state.windows.length);
    wnd.zIndex = this.state.windows.length;

    this.state.windows.forEach(w => (w.isActive = false));
    wnd.isActive = true;
    this.setState({
      windows: this.state.windows.slice(),
    });
  }

  activateMostRecentWindow() {
    if (!this.state.windows.length) return;

    const wnds = this.state.windows.slice();
    wnds.sort((a, b) => genericOrderCompareFn(b.zIndex, a.zIndex));
    this.activateWindow(wnds[0]);
  }

  minimizeWindow(wnd: WindowInstance) {
    this.markAsMinimized(wnd);
    this.setState({
      windows: this.state.windows.slice(),
    });
  }

  closeWindow(wnd: WindowInstance) {
    const idx = this.state.windows.indexOf(wnd);
    if (idx >= 0) {
      const windows = this.state.windows.slice();
      windows.splice(idx, 1);
      this.setState({
        windows,
      });
    }
  }

  setWindowTitle(wnd: WindowInstance, title: string) {
    wnd.title = title;
    this.setState({
      windows: this.state.windows.slice(),
    });
  }

  setWindowTitleExtension(wnd: WindowInstance, titleExtension: string | undefined) {
    wnd.titleExtension = titleExtension;
    this.setState({
      windows: this.state.windows.slice(),
    });
  }

  closeAllWindows() {
    this.setState({
      windows: [],
    });
  }

  minimizeAllWindows() {
    this.setState({
      windows: this.state.windows.map(x => {
        this.markAsMinimized(x);
        return x;
      }),
    });
  }

  setDetectedWindowEdge(edge: WindowEdgeDetection) {
    if (edge === this.state.currentEdgeDetection) return;

    this.setState({
      currentEdgeDetection: edge,
    });
  }

  private markAsMinimized(wnd: WindowInstance) {
    wnd.minimized = true;
    wnd.isActive = false;
  }
}

function findAndLowerWindowWithIndex(state: DesktopState, zIndex: number) {
  const wnd = state.windows.filter(x => x.zIndex === zIndex)[0];
  if (!wnd) return;

  findAndLowerWindowWithIndex(state, wnd.zIndex - 1);
  wnd.zIndex--;
}

export const desktopState = new DesktopStateStore({
  windows: [],
  currentEdgeDetection: "inactive",
  desktopWidth: 800,
  desktopHeight: 600
});
