import { Text } from "core/content";
import { tx } from "core/intl";
import { LoadingSpinner } from "./LoadingSpinner";
import { PendingOperationHook, usePendingOperation } from "./PendingOperationHook";

interface TableLoadingRowProps {
  promise: Promise<any> | true | undefined;
  disableLoadingIf?: any;
  rows?: any[];
  colSpan?: number;
  pendingTxt?: string;
  errorTxt?: string;
  noRowsTxt?: string;
}

const alwaysPendingPromise = new Promise(() => {});

export function TableLoadingRow(props: TableLoadingRowProps) {
  const { colSpan, promise, disableLoadingIf, noRowsTxt, pendingTxt, errorTxt } = props;
  const op = usePendingOperation(promise === true ? alwaysPendingPromise : promise);

  if ((op.state.pending && !disableLoadingIf) || op.state.error) {
    if (op.state.pending) {
      return (
        <tr>
          <td colSpan={colSpan} className="text-center">
            <Text>{pendingTxt || tx("Loading...")}</Text>
            <LoadingSpinner />
          </td>
        </tr>
      );
    } else if (op.state.error) {
      return (
        <tr>
          <td className="text-center text-danger" colSpan={props.colSpan}>
            {op.state.errorMessage || <Text>{errorTxt || tx("Loading error")}</Text>}
            {op.state.technicalError && <span>({op.state.technicalError})</span>}
          </td>
        </tr>
      );
    } else {
      return null;
    }
  } else if (shouldShowNoRowsFeedback(props, op)) {
    return (
      <tr>
        <td className="text-center" colSpan={props.colSpan}>
          <Text>{noRowsTxt || tx("No records")}</Text>
        </td>
      </tr>
    );
  } else {
    return null;
  }
}

function shouldShowNoRowsFeedback(props: TableLoadingRowProps, op: PendingOperationHook<any>): boolean {
  if (props.rows && props.rows.length === 0) {
    return true;
  }

  if (
    op.state.success &&
    !props.hasOwnProperty("dataForNoRowsInfo") &&
    Array.isArray(op.state.rawResult) &&
    op.state.rawResult.length === 0
  ) {
    return true;
  }

  return false;
}
