import { lazy } from "react";
import { tx } from "core/intl";
import { useInitiallyMaximizedWindow } from "../../core/desktop/WindowViewContext";
import { SystemState } from "../../core/global/systemState";
import { defineWindowDetails, useInitWindowSize } from "core/desktop";
import { WindowLazyContent } from "core/desktop/WindowLazyContent";

const VehiclePosLogWindowContent = lazy(() =>
  import("./VehiclePosLogWindowContent").then(m => ({ default: m.VehiclePosLogWindowContent }))
);

defineWindowDetails(VehiclePosLogWindow, {
  icon: "fas fa-crosshairs",
  title: tx("Vehicle location history"),
  isAvailable: isWindowAvailable,
});

export function VehiclePosLogWindow() {
  useInitWindowSize(800, 600);
  useInitiallyMaximizedWindow();

  return (
    <WindowLazyContent>
      <VehiclePosLogWindowContent />
    </WindowLazyContent>
  );
}

function isWindowAvailable(ss: SystemState) {
  const isBusModuleEnabled = (ss.config && ss.config.isBusModuleEnabled) || false;
  if (!isBusModuleEnabled) return false;

  return Boolean(ss.privs.vehiclePosLog);
}
