import { useState } from "react";
import { systemState } from "core/global/systemState";
import { Text } from "core/content";
import { LoginRequiredActionForm } from "./LoginRequiredActionForm";
import { LoginForm } from "./LoginForm";

interface LoginProcessHandlerProps {
  login?: string;
}

export function LoginProcessHandler(props: LoginProcessHandlerProps) {
  const [requiredActionView, setRequiredActionView] = useState(false);
  const lastLogoutReason = systemState.useGlobalState(gs => gs.lastLogoutReason);

  return (
    <div className="login-form">
      <div className="text-center">
        <h4 className="login-hint">
          <Text>Aby kontynuować, zaloguj się</Text>
        </h4>
      </div>

      {lastLogoutReason && (
        <Text block center danger className="mb-16">
          {lastLogoutReason}
        </Text>
      )}
      {requiredActionView ? (
        <LoginRequiredActionForm onGoBackToLoginForm={() => setRequiredActionView(false)} />
      ) : (
        <LoginForm login={props.login} onLoginActionRequired={() => setRequiredActionView(true)} />
      )}

      <div className="vertical-asymetric-form-spacer"></div>
    </div>
  );
}
