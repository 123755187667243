import React from "react";
import "./YesNoDialog.scss";
import { withWindowContext, InjectedWindowContext } from "../WindowViewContext";
import { Grid, GridItem } from "../../layout/Grid";
import { Button } from "../../forms";

interface YesNoDialogProps extends InjectedWindowContext {
  title: string;
  question: string;
  yesLabel?: string;
  noLabel?: string;
}

export const YesNoDialog = withWindowContext(
  class YesNoDialog extends React.Component<YesNoDialogProps> {
    static title = "Pytanie";
    static icon = "fas fa-question";

    yes = () => this.props.window.close(true);
    no = () => this.props.window.close(false);

    render() {
      return (
        <Grid gap className="yes-no-dialog">
          <GridItem rowTemplate="1fr">
            <div className="question">{this.props.question}</div>
          </GridItem>

          <GridItem rowTemplate="auto" className="bottom-panel">
            <Button onClick={this.no}>{this.props.noLabel || "Anuluj"}</Button>
            <Button cta onClick={this.yes}>
              {this.props.yesLabel || "Zatwierdź"}
            </Button>
          </GridItem>
        </Grid>
      );
    }

    componentDidMount() {
      this.props.window.setTitle(this.props.title);
      this.props.window.setSize([400, 200]);
    }
  }
);
