import { contextHelp } from "core/contextHelp";
import { useTr } from "core/intl";
import { Icon, useDataLoader } from "core/utils";

interface WindowContextHelpButtonProps {
  code: string | undefined;
}

export function WindowContextHelpButton(props: WindowContextHelpButtonProps) {
  const { code } = props;
  const [metadata] = useDataLoader(() => (code ? contextHelp.getMetadata(code) : Promise.resolve(undefined)));
  const tr = useTr();

  if (!metadata || !metadata.hasContent) return null;

  const open = () => {
    contextHelp.openHelp(metadata);
  };

  return (
    <button onClick={open} title={tr("Otwórz pomoc kontekstową związaną z tym oknem")}>
      <Icon symbol="question" />
    </button>
  );
}
