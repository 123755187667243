const baseColors = {
  black100: "#161616",
  gray100: "#262626",
  gray90: "#393939",
  gray80: "#4C4C4C",
  gray70: "#616161",
  gray60: "#6F6F6F",
  gray50: "#8D8D8D",
  gray40: "#A8A8A8",
  gray35: "#B8B8B8",
  gray30: "#C8C8C8",
  gray20: "#E4E4E4",
  gray10: "#F2F2F2",
  white: "#FFFFFF",
  correctSolid: "#5FC375",
  warningSolid: "#FFCE4F",
  errorSolid: "#FF7161",
  informationSolid: "#7976FF",
  noCommunicationSolid: "#8D8D8D",
  blueSolid: "#3787FF",
  blueSolidDark: "#0F62FE",
  blueSolidMiddleGradient: "#3155e6",
};

export const colors = {
  ...baseColors,
  muted: baseColors.gray40,
  textLowEmphasis: baseColors.gray40,
  info: baseColors.blueSolid,
  warning: baseColors.warningSolid,
  error: baseColors.errorSolid,
  success: baseColors.correctSolid,
};
