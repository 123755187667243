import React from "react";
import ReactDOM from "react-dom";
import {
  ItemDisplayFn,
  ItemFilterPredicate,
  ItemFiltering,
  ItemKeyFn,
  ItemRenderFn,
  ItemSearchValueFn,
  selectUtils,
} from "../utils/selectUtils";
import Popper, { Placement } from "popper.js";
import { createFilterPredicateForItem, domEventHelpers, tokenMatcherParse } from "core/utils";
import { KeyCodes } from "core/utils";
import { toClassNames, getBodyPortal } from "../utils/reactHelpers";
import { Checkbox } from ".";
import { Text } from "core/content";
import { globalTr } from "core/intl";

interface MultiselectViewProps<TItem> {
  items: TItem[] | undefined;
  selected: TItem[] | undefined;
  itemKey?: keyof TItem | ItemKeyFn<TItem>;
  itemKeyAsModel?: boolean;
  itemRender?: ItemRenderFn<TItem>;
  display?: keyof TItem | ItemDisplayFn<TItem>;
  searchValue?: keyof TItem | ItemSearchValueFn<TItem>;
  placement?: Placement;
  onSelect: (item: TItem) => any;
  popoverRef: HTMLElement;
  onOutsideClick?: () => any;
  searchText?: string;
  filtering?: ItemFiltering<TItem>;
  noResultMessage?: string;
}

interface MultiselectViewState<TItem> {
  items: TItem[];
  itemsCountLimit: number;
  focusedIndex: number;
}

export class MultiselectView<TItem> extends React.Component<MultiselectViewProps<TItem>, MultiselectViewState<TItem>> {
  static rootClassName = "n-dropdown n-multiselect-view";
  static defaultItemsLimit = 100;

  popper?: Popper;
  rootEl = React.createRef<HTMLDivElement>();

  constructor(props: MultiselectViewProps<TItem>) {
    super(props);

    this.state = {
      items: [],
      itemsCountLimit: MultiselectView.defaultItemsLimit,
      focusedIndex: -1,
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.getSearchValue = this.getSearchValue.bind(this);
  }

  render() {
    return ReactDOM.createPortal(this.renderView(), getBodyPortal());
  }

  renderView() {
    const minWidth = this.props.popoverRef.getBoundingClientRect().width;

    return (
      <div
        className={MultiselectView.rootClassName}
        onMouseDown={domEventHelpers.stopPropagationAndPrevent}
        ref={this.rootEl}
        style={{ minWidth }}
        onClick={domEventHelpers.stopPropagationAndPrevent}
      >
        <ul>
          {this.renderItems()}
          {this.renderNoResultMessage()}
          {this.renderMoreItemsMessage()}
        </ul>
      </div>
    );
  }

  renderItems() {
    const selectedItemsKeys = this.getModelKeyValues();
    const items =
      this.state.items.length > this.state.itemsCountLimit
        ? this.state.items.slice(0, this.state.itemsCountLimit)
        : this.state.items;

    return items.map((item, i) => this.renderItem(item, i, selectedItemsKeys));
  }

  renderItem(item: TItem, index: number, selectedItemKeys: any[]) {
    const itemKey = this.getItemKey(item);
    const focused = index === this.state.focusedIndex;
    const selected = selectedItemKeys.includes(itemKey);

    return (
      <li
        key={itemKey}
        className={toClassNames({
          focused,
        })}
        onClick={ev => this.onItemClick(ev, item)}
      >
        <Checkbox value={selected} onChange={() => {}}>
          {this.props.itemRender ? this.props.itemRender(item) : this.formatItemDisplay(item)}
        </Checkbox>
      </li>
    );
  }

  renderNoResultMessage() {
    if (this.state.items && this.state.items.length) return null;

    const noResultMessage = this.props.noResultMessage || <Text>Brak elementów</Text>;
    return <li className="no-elements-feedback">{noResultMessage}</li>;
  }

  renderMoreItemsMessage() {
    if (this.state.items.length <= this.state.itemsCountLimit) return null;

    const moreItemsCount = this.state.items.length - this.state.itemsCountLimit;
    return (
      <li onClick={ev => this.showMoreItems(ev)}>
        <i>{globalTr("+{{moreItemsCount}} elementów", { moreItemsCount })}</i>
      </li>
    );
  }

  componentDidMount() {
    this.popper = new Popper(this.props.popoverRef, this.rootEl.current!, {
      placement: this.props.placement,
    });
    this.popper.scheduleUpdate();
    document.addEventListener("click", this.onDocumentClick);
    this.updateFilteredItems();
  }

  componentDidUpdate(prevProps: MultiselectViewProps<TItem>, prevState: MultiselectViewState<TItem>) {
    if (prevProps.items !== this.props.items || prevProps.searchText !== this.props.searchText) {
      this.updateFilteredItems();
    }

    if (prevProps.items !== this.props.items && this.state.focusedIndex !== 0) {
      this.resetfocusedIndex();
    } else if (prevProps.searchText !== this.props.searchText) {
      this.setState({
        focusedIndex: this.props.searchText ? 0 : -1,
      });
    }

    if (prevState.items !== this.state.items) {
      this.popper && this.popper.update();
    }
  }

  componentWillUnmount() {
    if (this.popper) {
      this.popper.destroy();
    }
    document.removeEventListener("click", this.onDocumentClick);
  }

  onDocumentClick() {
    this.props.onOutsideClick && this.props.onOutsideClick();
  }

  onItemClick(ev: React.MouseEvent, item: TItem) {
    domEventHelpers.stopPropagationAndPrevent(ev);
    window.setTimeout(() => this.select(item));
  }

  onKeydown(event: React.KeyboardEvent) {
    switch (event.keyCode) {
      case KeyCodes.downarrow:
        event.preventDefault();
        event.stopPropagation();
        this.focusNext();
        break;

      case KeyCodes.uparrow:
        event.preventDefault();
        event.stopPropagation();
        this.focusPrev();
        break;

      case KeyCodes.space:
        if (event.ctrlKey) {
          event.preventDefault();
          event.stopPropagation();
          this.toggleFocused();
        }
        break;

      case KeyCodes.enter:
        event.preventDefault();
        event.stopPropagation();
        this.toggleFocused();
        break;

      default:
        break;
    }
  }

  updateFilteredItems() {
    const searchText = this.props.searchText || "";
    const searchTextLower = searchText.toLowerCase();
    let items = this.props.items || [];

    if (searchText) {
      const predicate = this.getFilterPredicate(searchText);
      items = items.filter(x => predicate(x, searchText, searchTextLower));
    }

    this.setState({
      items,
    });
  }

  getFilterPredicate(searchText: string): ItemFilterPredicate<TItem> {
    const filteringMode = this.props.filtering || "multitokenIncludes";

    return createFilterPredicateForItem(filteringMode, this.getSearchValue, searchText);
  }

  getSearchValue(item: TItem): string {
    return selectUtils.formatItemDisplay(item, this.props.searchValue || this.props.display);
  }

  focusNext() {
    if (!this.state.items) return;

    this.setState({
      focusedIndex: (this.state.focusedIndex + 1) % this.state.items.length,
    });

    this.scrollToFocused();
  }

  focusPrev() {
    if (!this.props.items) return;

    let newfocusedIndex = this.state.focusedIndex - 1;
    if (newfocusedIndex < 0) newfocusedIndex = this.state.items.length - 1;

    this.setState({
      focusedIndex: newfocusedIndex,
    });

    this.scrollToFocused();
  }

  scrollToFocused() {
    Promise.resolve().then(() => {
      if (this.rootEl.current) {
        let focusedEl = this.rootEl.current.querySelector(".focused");
        if (focusedEl) {
          focusedEl.scrollIntoView(false);
        }
      }
    });
  }

  resetfocusedIndex() {
    this.setState({
      focusedIndex: 0,
    });
    this.scrollToFocused();
  }

  getFocusedItem() {
    const items = this.state.items;
    if (!items.length || this.state.focusedIndex < 0) return undefined;

    return items[this.state.focusedIndex];
  }

  toggleFocused() {
    const focusedItem = this.getFocusedItem();
    if (!focusedItem) return;

    this.select(focusedItem);
  }

  select(item: TItem) {
    this.props.onSelect(item);
  }

  getItemKey(item: any) {
    return selectUtils.getItemKey(item, this.props.itemKey);
  }

  formatItemDisplay(item: any) {
    return selectUtils.formatItemDisplay(item, this.props.display);
  }

  getModelKeyValues() {
    const modelValues = this.props.selected && Array.isArray(this.props.selected) ? this.props.selected : [];
    if (this.props.itemKeyAsModel) return modelValues;

    return modelValues.map(x => this.getItemKey(x));
  }

  showMoreItems(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.setState(s => ({
      itemsCountLimit: s.itemsCountLimit + MultiselectView.defaultItemsLimit,
    }));
  }
}
