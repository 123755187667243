import React from "react";
import "./FileUpload.scss";
import { FileUploadResult } from "../utils/fileUploadResult";
import { FeedbackMessage, Text } from "../content";
import { combineClassNames, LoadingSpinner } from "core/utils";
import { tx } from "core/intl";

interface FileUploadProps {
  accept?: string;
  children?: React.ReactNode;
  onFileUpload: (result: FileUploadResult) => any;
  style?: React.CSSProperties;
}

interface FileUploadState {
  isHoverWithFile: boolean;
  processingFile: boolean;
  fileName: string;
  err: string;
}

const b64reg = /(?:data:(.*);base64,)(.*)/;

export class FileUpload extends React.Component<FileUploadProps, FileUploadState> {
  constructor(props: FileUploadProps) {
    super(props);

    this.onFileChange = this.onFileChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);

    this.state = {
      processingFile: false,
      isHoverWithFile: false,
      fileName: "",
      err: "",
    };
  }

  render() {
    const { processingFile, fileName, err, isHoverWithFile } = this.state;
    const cls = combineClassNames("file-upload", isHoverWithFile ? "hover-with-file" : undefined);

    return (
      <div className={cls} style={this.props.style}>
        <input
          type="file"
          accept={this.props.accept}
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onChange={this.onFileChange}
        />
        <div className="feedback-state">
          {!processingFile && <Text>{this.props.children || tx("Kliknij lub przeciągnij tutaj plik")}</Text>}
          {processingFile && (
            <span>
              Przetwarzanie {fileName}... <LoadingSpinner />
            </span>
          )}
          {err && <FeedbackMessage kind="error">{err}</FeedbackMessage>}
        </div>
      </div>
    );
  }

  onDrop(ev: React.DragEvent<HTMLInputElement>) {
    this.updateIsHoverWithFile(false);

    if (this.state.processingFile) return;

    if (ev.dataTransfer.files.length) {
      const target = ev.target as HTMLInputElement;
      this.processFile(ev.dataTransfer.files[0], target);
    }
  }

  onDragEnter(ev: React.DragEvent<HTMLInputElement>) {
    this.updateIsHoverWithFile(true);
  }

  onDragLeave(ev: React.DragEvent<HTMLInputElement>) {
    this.updateIsHoverWithFile(false);
  }

  updateIsHoverWithFile(isHover: boolean) {
    this.setState({
      isHoverWithFile: isHover,
    });
  }

  onFileChange(ev: React.ChangeEvent<HTMLInputElement>) {
    if (this.state.processingFile) return;

    const target = ev.target as HTMLInputElement;
    const files = target.files;
    if (!files || !files.length) return;

    this.processFile(files[0], target);
  }

  processFile(file: File, input: HTMLInputElement) {
    this.setState({
      processingFile: true,
      fileName: file.name,
      err: "",
    });

    const result: FileUploadResult = {
      name: file.name,
      size: file.size,
      type: file.type,
      base64: "",
    };

    const reader = new FileReader();
    reader.onload = ev => {
      this.setState({
        processingFile: false,
      });
      const dataUrl = (reader.result as string) || "";
      const match = b64reg.exec(dataUrl);
      if (!match) {
        this.setState({
          err: "Błąd przesyłania danych",
          processingFile: false,
        });
        input.value = '';
        return;
      }
      result.base64 = match[2];
      this.props.onFileUpload(result);
      input.value = '';
    };
    reader.onerror = err => {
      this.setState({
        processingFile: false,
        err: "Błąd wgrywania pliku",
      });
      input.value = '';
    };
    reader.readAsDataURL(file);
  }
}
