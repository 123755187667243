import { defineWindowDetails, useInitWindowSize } from "core/desktop";
import { WindowLazyContent } from "core/desktop/WindowLazyContent";
import { tx } from "core/intl";
import { lazy } from "react";

const ItcStateStatsWindowContent = lazy(() =>
  import("./ItcStateStatsWindowContent").then(m => ({ default: m.ItcStateStatsWindowContent }))
);

defineWindowDetails(ItcStateStatsWindow, {
  icon: "fas fa-list-ul",
  title: tx("Controllers state"),
  isAvailable: ss => ss.privs.itcStateStats,
});

export function ItcStateStatsWindow() {
  useInitWindowSize(380, 550);

  return (
    <WindowLazyContent>
      <ItcStateStatsWindowContent />
    </WindowLazyContent>
  );
}
