import { WindowSerializedState } from "./desktopSave/DesktopSerializedState";
import { WindowCloseHandler, WindowStaticProps } from "./WindowTypes";
import { WindowView } from "./WindowView";

let counter = 1;

export class WindowInstance<TProps = any> {
  id: number;
  title: string;
  titleExtension?: string;
  isActive: boolean;
  minimized: boolean;
  zIndex: number;
  props: TProps;
  closeHandlers: WindowCloseHandler[];
  restoreState?: WindowSerializedState;
  windowViewHandlingInstance?: WindowView<TProps>;

  constructor(
    public component: React.ComponentType<TProps> & WindowStaticProps,
    props: TProps,
    restoreState?: WindowSerializedState
  ) {
    this.id = counter++;
    this.title = "Window";
    this.isActive = false;
    this.minimized = false;
    this.zIndex = 0;
    this.closeHandlers = [];
    this.props = props;
    this.restoreState = restoreState;
  }

  onClose(handler: WindowCloseHandler) {
    this.closeHandlers.push(handler);
    return this;
  }
}

export function defineWindowDetails(
  cmp: React.ComponentType<any>,
  statics: WindowStaticProps
) {
  Object.assign(cmp, statics);
}
