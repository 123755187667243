import { NativeModalWindow } from "./NativeModalWindow";
import { ItcDetailsWindow } from "../../domain/itc/details/ItcDetailsWindow";
import { useParams } from "react-router-dom";

export function ItcModal() {
  const itcIdStr = useParams().id;
  const itcId = Number(itcIdStr);

  return <NativeModalWindow>{() => <ItcDetailsWindow itcId={itcId} />}</NativeModalWindow>;
}
