import { clientConfigService } from "apinet";
import { MapBusDelayToPriorityChanged } from "common/eventBusTypes";
import { useEventRandomDebounced } from "core/events";
import { systemState } from "./systemState";

export function ClientExposedConfigRefresher() {
  useEventRandomDebounced<MapBusDelayToPriorityChanged>(
    "mapBusDelayToPriorityChanged",
    () => {
      clientConfigService.getClientExposedConfig().then(res => systemState.setConfig(res));
    },
    1000,
    3000
  );

  return null;
}
