import { systemState } from "core/global";

export const externalLinkOpenUtils = {
  openFromPromise,
  open,
};

function openFromPromise(urlPromiseGetter: () => Promise<string | undefined>) {
  if (systemState.state.isPresentationModeActive) return;

  urlPromiseGetter()
    .then(url => {
      if (url) {
        open(url);
      }
    })
    .catch(err => {
      console.error(err);
    });
}

function open(url: string) {
  if (systemState.state.isPresentationModeActive) return;

  window.open(url, "_blank");
}
