import React, { KeyboardEventHandler } from "react";
import { combineClassNames } from "../../utils/reactHelpers";
import { WithTranslation, withTranslation } from "react-i18next";
import { IHasInnerRef } from "core/utils/innerRef";

interface TextAreaProps extends WithTranslation, Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
  value: string | undefined;
  onChange: (value: string) => any;
  name?: string;
  maxLength?: number;
  height?: string;
  onEnter?: KeyboardEventHandler<HTMLTextAreaElement>;
  noTranslate?: boolean;
}

export const TextArea = withTranslation()(
  class TextArea extends React.PureComponent<TextAreaProps> implements IHasInnerRef {
    static rootClassName = "";

    inputRef = React.createRef<HTMLTextAreaElement>();

    constructor(props: TextAreaProps) {
      super(props);

      this.onChange = this.onChange.bind(this);
      this.onKeyDown = this.onKeyDown.bind(this);
    }

    getInnerRef(): HTMLElement | null {
      return this.inputRef.current;
    }

    render() {
      let { value, type, className, onEnter, style, height, placeholder, t, tReady, noTranslate, ...restAttrs } =
        this.props;
      const val = value || "";
      const classNames = combineClassNames(TextArea.rootClassName, className);
      const placeholderToRender = typeof placeholder === "string" && !noTranslate ? t(placeholder) : placeholder;

      if (height) {
        style = Object.assign({}, style || {}, { height });
      }

      return (
        <textarea
          {...restAttrs}
          style={style}
          className={classNames}
          ref={this.inputRef}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          value={val}
          placeholder={placeholderToRender}
        />
      );
    }

    onChange(ev: React.ChangeEvent<HTMLTextAreaElement>) {
      const newModel = this.restrictNewModel(ev.target.value);
      this.props.onChange(newModel);
    }

    onKeyDown(ev: React.KeyboardEvent<HTMLTextAreaElement>) {
      this.props.onKeyDown && this.props.onKeyDown(ev);

      if (this.props.onEnter && ev.key === "Enter") {
        this.props.onEnter(ev);
      }
    }

    restrictNewModel(value: string): string {
      if (this.props.maxLength && value.length > this.props.maxLength) {
        value = value.substring(0, this.props.maxLength);
      }

      return value;
    }
  }
);
