import React, { SetStateAction } from "react";

export function toClassNames(obj: {[key: string]: boolean | undefined}): string {
  return Object.keys(obj).filter(x => obj[x]).join(' ');
}

export function combineClassNames(...classNames: Array<string | false | undefined>): string {
  return classNames.filter(Boolean).join(" ");
}

export const reactChild = {
  isReactElement: function (child: React.ReactNode): child is React.ReactElement<any> {
    return typeof child === 'object';
  }
}

export const typedMemo: <T>(c: T) => T = React.memo;


export function getBodyPortal() {
  const id = 'ncomponents-react-body-portal';

  let el = document.getElementById(id);
  if (!el) {
    el = document.createElement('div');
    el.id = id;
    // el.style.position = 'absolute';
    // el.style.top = '0px';
    // el.style.left = '0px';
    // el.style.width = '100vh';
    document.body.appendChild(el);
  }

  return el;
}

export function isSetStateActionLiteralObject<T>(arg: SetStateAction<T>): arg is T {
  return typeof arg !== 'function';
} 

export function evaluteSyncSetStateAction<T>(prevModel: T, setState: SetStateAction<T>): T {
  if (isSetStateActionLiteralObject(setState)) {
    return setState;
  }

  return setState(prevModel);
}