import { AxiosError } from "axios";
import { globalTr } from "core/intl";
import { createValidationObjectDataFromServerResponse, ValidationObjectData } from "core/validation";
import { isValidationError, isAccessDeniedError } from "../common/errorTypes";
import { RejectedPromiseUserFeedback } from "../core/utils/RejectedPromiseUserFeedback";

const convertedErrorFlag = {};

interface ConvertedError {
  convertedErrorFlag: any;
  message: string;
  originalError: any;
  originalMessage?: string;
  validationObject?: ValidationObjectData;
}

export function convertErrorToUserMessage(err: any): ConvertedError {
  if (err.convertedErrorFlag === convertedErrorFlag) return err;

  if (err instanceof AxiosError) {
    if (err.response?.status === 400 || err.response?.status === 403) {
      const data = err.response.data;
      if (isValidationError(data)) {
        return {
          convertedErrorFlag,
          message: data.message || globalTr("Incorrect data"),
          originalError: err,
          validationObject: createValidationObjectDataFromServerResponse(data),
        };
      } else if (isAccessDeniedError(data)) {
        return {
          convertedErrorFlag,
          message: globalTr("No permission to perform the operation"),
          originalMessage: data.message,
          originalError: err,
        };
      }
    }
  }

  if (err instanceof RejectedPromiseUserFeedback) {
    return {
      convertedErrorFlag,
      message: err.message,
      originalError: err,
    };
  }

  return {
    convertedErrorFlag,
    message: globalTr("Operation error"),
    originalMessage: err.response && err.response.data && err.response.data.message,
    originalError: err,
  };
}
