import React from "react";
import { FormContext } from "./FormContext";
import { DateTimeInput } from "../controls/DateTimeInput";

type DateTimeInputProps = React.ComponentProps<typeof DateTimeInput>;

interface DateTimeFieldProps extends Omit<DateTimeInputProps, "value" | "onChange"> {
  name: string;
}

export class DateTimeField extends React.PureComponent<DateTimeFieldProps> {
  static contextType = FormContext;
  declare context: React.ContextType<typeof FormContext>;

  constructor(props: DateTimeFieldProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  render() {
    const { name, ...restAttrs } = this.props;
    const value = this.context.model[name];

    return <DateTimeInput value={value} onChange={this.onChange} {...restAttrs} />;
  }

  onChange(newValue: any) {
    this.context.handleFieldChange(this.props.name, newValue);
  }
}
