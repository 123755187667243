const version = import.meta.env.VITE_APP_VERSION;
const build = import.meta.env.VITE_APP_BUILD;
const bundledTimestamp = import.meta.env.VITE_BUILD_TIMESTAMP;

interface BundledAppVersionInfo {
  version: string;
  build: string;
  bundledTimestamp: number | undefined;
  fullVersion: string;
}

const bundledTimestampNumber = bundledTimestamp && !isNaN(bundledTimestamp) ? Number(bundledTimestamp) : undefined;
const bundledDate = bundledTimestampNumber ? new Date(bundledTimestamp * 1000) : undefined;
const fullVersionDate = bundledDate
  ? [
      bundledDate.getUTCFullYear(),
      (bundledDate.getUTCMonth() + 1).toString().padStart(2, "0"),
      bundledDate.getUTCDate().toString().padStart(2, "0"),
      bundledDate.getUTCHours().toString().padStart(2, "0"),
      bundledDate.getUTCMinutes().toString().padStart(2, "0"),
      bundledDate.getUTCSeconds().toString().padStart(2, "0"),
    ].join("")
  : undefined;
const fullVersion = [version, build, fullVersionDate].filter(Boolean).join("-");

export const bundledAppVersionInfo: BundledAppVersionInfo = {
  version,
  build,
  bundledTimestamp: bundledTimestampNumber,
  fullVersion,
};
