import React, { useState, useRef, useEffect, SetStateAction, Dispatch } from "react";
import { convertErrorToUserMessage } from "../../api/apiUtils";
import { PendingOperationState } from "./PendingOperation";
import { ValidationObjectData } from "core/validation";

export interface PendingOperationHook<T = any> {
  handle: (promise: Promise<T> | undefined) => void;
  promise: Promise<T> | undefined;
  state: PendingOperationState;
  syncPending: boolean;
}

const emptyValidationObjectData: ValidationObjectData = {};

export function usePendingOperation<T = any>(promise?: Promise<T>): PendingOperationHook {
  const [state, setState] = useState<PendingOperationState>({
    pending: false,
    success: false,
    error: false,
    operationCounter: 0,
    errorMessage: "",
    technicalError: "",
    validation: emptyValidationObjectData,
  });
  const destroyedRef = useRef(false);
  const hookHandleRef: React.MutableRefObject<PendingOperationHook<T>> = useRef<PendingOperationHook<T>>({
    state,
    handle: (promise: Promise<T> | undefined) =>
      handleNewPromise(promise, setState, hookHandleRef.current, destroyedRef),
    promise,
    syncPending: false,
  });

  useEffect(() => {
    destroyedRef.current = false;

    return () => {
      destroyedRef.current = true;
    };
  }, []);
  useEffect(() => hookHandleRef.current.handle(promise), [promise]);

  hookHandleRef.current.state = state;
  return hookHandleRef.current;
}

function handleNewPromise(
  promise: Promise<any> | undefined,
  setState: Dispatch<SetStateAction<PendingOperationState>>,
  hookHandle: PendingOperationHook,
  destroyedRef: React.MutableRefObject<boolean>
): void {
  hookHandle.promise = promise;
  hookHandle.syncPending = promise ? true : false;

  if (destroyedRef.current) return;

  setState(prev => ({
    pending: promise ? true : false,
    success: false,
    error: false,
    errorMessage: "",
    technicalError: "",
    operationCounter: promise ? prev.operationCounter + 1 : prev.operationCounter,
    validation: emptyValidationObjectData,
  }));

  if (!promise) return;

  promise
    .then(res => {
      if (hookHandle.promise !== promise) return;
      if (destroyedRef.current) return;

      setState(prev => ({
        pending: false,
        success: true,
        error: false,
        errorMessage: "",
        technicalError: "",
        rawResult: res,
        operationCounter: prev.operationCounter,
        validation: emptyValidationObjectData,
      }));
      hookHandle.syncPending = false;
    })
    .catch(ex => {
      if (hookHandle.promise !== promise) return;
      if (destroyedRef.current) return;

      const err = convertErrorToUserMessage(ex);

      setState(prev => ({
        pending: false,
        success: false,
        error: true,
        errorMessage: err.message || "",
        technicalError: err.originalMessage || "",
        validation: err.validationObject || emptyValidationObjectData,
        rawError: ex,
        operationCounter: prev.operationCounter,
      }));
      hookHandle.syncPending = false;
    });
}
