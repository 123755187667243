import React from "react";
import { combineClassNames } from "../utils";
import "./Stack.scss";

interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  gap?: string | true;
}

export function Stack(props: StackProps) {
  const { gap, className, style, ...restAttrs } = props;

  const cls = combineClassNames("stack-layout", className);
  const finalStyle: React.CSSProperties = Object.assign({}, style);

  if (gap) {
    finalStyle.gap = gap === true ? "16px" : gap;
  }

  return <div className={cls} style={finalStyle} {...restAttrs} />;
}
