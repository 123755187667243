import React, { useCallback, useEffect, useRef } from "react";
import { combineClassNames } from "./reactHelpers";
import { usePendingOperation } from "./PendingOperationHook";
import "./Icon.scss";
import { LoadingSpinner } from "./LoadingSpinner";
import { generalDialogs } from "core/desktop";
import { useTr } from "core/intl";
import { useDelayedEffect } from "./miscHooks";
import { domEventHelpers } from "./domEventsHelpers";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const classNameMapping = {
  filter: "fas fa-filter",
  filterCricleXmark: "fas fa-filter-circle-xmark",
  arrowUp: "fas fa-arrow-up",
  arrowLeft: "fas fa-arrow-left",
  arrowRight: "fas fa-arrow-right",
  arrowDown: "fas fa-arrow-down",
  arrowRotateLeft: "fas fa-arrow-rotate-left",
  arrowRotateRight: "fas fa-arrow-rotate-right",
  angleUp: "fas fa-angle-up",
  angleDown: "fas fa-angle-down",
  angleLeft: "fas fa-angle-left",
  angleRight: "fas fa-angle-right",
  info: "fas fa-info",
  infoCricle: "fas fa-info-circle",
  question: "fas fa-question",
  wrench: "fas fa-wrench",
  video: "fas fa-video",
  edit: "fas fa-edit",
  mapRegular: "far fa-map",
  mapMarkedAlt: "fas fa-map-marked-alt",
  trashAlt: "fas fa-trash-alt",
  minus: "fas fa-minus",
  plus: "fas fa-plus",
  plusSquare: "fas fa-plus-square",
  plusCircle: "fas fa-plus-circle",
  circle: "fas fa-circle",
  pen: "fas fa-pen",
  folderOpen: "fas fa-folder-open",
  lock: "fas fa-lock",
  lockOpen: "fas fa-lock-open",
  windowMinimize: "far fa-window-minimize",
  windowMaximize: "far fa-window-maximize",
  windoweRestore: "far fa-window-restore",
  windowClose: "far fa-window-close",
  clipboardList: "fas fa-clipboard-list",
  clipboard: "fas fa-clipboard",
  cliboardCheck: "fas fa-clipboard-check",
  history: "fas fa-history",
  times: "fas fa-times",
  paperclip: "fas fa-paperclip",
  commentAlt: "fas fa-comment-alt",
  commentSlash: "fas fa-comment-slash",
  undo: "fas fa-undo",
  exclamationTriangle: "fas fa-exclamation-triangle",
  exclamationCircle: "fas fa-exclamation-circle",
  clock: "fas fa-clock",
  angleDoubleLeft: "fas fa-angle-double-left",
  angleDoubleRight: "fas fa-angle-double-right",
  bars: "fas fa-bars",
  download: "fas fa-download",
  image: "fas fa-image",
  hammer: "fas fa-hammer",
  hourGlassHalf: "fas fa-hourglass-half",
  hourGlassStart: "fas fa-hourglass-start",
  hourGlassEnd: "fas fa-hourglass-end",
  check: "fas fa-check",
  checkCircle: "far fa-check-circle",
  checkCircleSolid: "fas fa-check-circle",
  trafficLight: "fas fa-traffic-light",
  cog: "fas fa-cog",
  spinner: "fas fa-spinner",
  userCircle: "fas fa-user-circle",
  userCircleRegular: "far fa-user-circle",
  signOutAlt: "fas fa-sign-out-alt",
  chartPie: "fas fa-chart-pie",
  chartLine: "fas fa-chart-line",
  chartArea: "fas fa-chart-area",
  eye: "fas fa-eye",
  eyeLight: "far fa-eye",
  eyeSlashLight: "far fa-eye-slash",
  xmark: "fas fa-xmark",
  circleXmark: "fas fa-xmark-circle",
  terminal: "fas fa-terminal",
  internetExplorer: "fab fa-internet-explorer",
  lightbulb: "fas fa-lightbulb",
  rawSymbolClass: "",
  search: "fas fa-magnifying-glass",
  squareCaretDown: "fas fa-square-caret-down",
  caretDown: "fas fa-caret-down",
  caretRight: "fas fa-caret-right",
  caretLeft: "fas fa-caret-left",
  file: "fas fa-file",
  envelope: "fas fa-envelope",
  crosshairs: "fas fa-crosshairs",
  arrowUpRightFromSquare: "fas fa-arrow-up-right-from-square",
  starSolid: "fas fa-star",
  starRegular: "far fa-star",
  broom: "fa-solid fa-broom",
  database: "fa-solid fa-database",
  rotate: "fa-solid fa-rotate",
  share: "fas fa-share",
  reply: "fas fa-reply",
  globe: "fas fa-globe",
  hand: "fas fa-hand",
  arrowDownWideShort: "fas fa-arrow-down-wide-short",
  arrowDownShortWide: "fas fa-arrow-down-short-wide",
  squareParking: "fas fa-square-parking",
  bolt: "fas fa-bolt",
  userSecret: "fas fa-user-secret",
  heartbeat: "fas fa-heartbeat",
  signHanging: "fas fa-sign-hanging",
  fileExport: "fas fa-file-export",
  repeat: "fas fa-repeat",
  listCheck: "fas fa-list-check",
  city: "fas fa-city",
  hourglassStart: "fas fa-hourglass-start",
  peopleArrows: "fas fa-people-arrows",
  copy: "fas fa-copy",
  arrowRightLeft: "fas fa-right-left",
  arrowSplitUpLeft: "fas fa-arrows-split-up-and-left",
  film: "fas fa-film",
  carOn: "fas fa-car-on",
  print: "fas fa-print",
  pauseCircle: "fas fa-circle-pause",
  timeline: "fas fa-timeline",
  gears: "fas fa-gears",
  waveSquare: "fas fa-wave-square",
  ban: "fas fa-ban",
  play: "fas fa-play",
  link: "fas fa-link",
};

export type IconSymbol = keyof typeof classNameMapping;

interface IconProps extends React.HTMLAttributes<HTMLElement> {
  icon?: IconProp;
  symbol?: IconSymbol;
  rawSymbolClass?: string;
  color?: string;
  gradientAccent?: boolean;
  gradientMode?: boolean;
  iconClassName?: string;
  spin?: boolean;
  noTranslate?: boolean;
  textSize?: boolean;
  successColor?: boolean;
  errorColor?: boolean;
  mutedColor?: boolean;
}

interface OperationIconProps<T> extends Omit<IconProps, "onClick"> {
  operation: () => Promise<T>;
  onBeforeOperationCheck?: () => Promise<boolean>;
  onSuccess?: (result: T) => any;
  onError?: (err: any) => any;
  showSuccessIcon?: boolean;
  resetStateTrigger?: any;
  resetStateAfterDelay?: number;
}

interface RemoveEntityIconProps<T>
  extends Omit<OperationIconProps<T>, "resetStateTrigger" | "symbol" | "showSuccessIcon"> {
  symbol?: IconSymbol;
  removeConfirmMessageFactory: () => string;
}

export function Icon(props: IconProps) {
  const tr = useTr();

  let {
    icon,
    symbol,
    rawSymbolClass,
    color,
    gradientAccent,
    gradientMode,
    iconClassName,
    textSize,
    spin,
    style,
    className,
    title,
    noTranslate,
    successColor,
    errorColor,
    mutedColor,
    children,
    ...restProps
  } = props;
  const clsName = combineClassNames(
    "icon-container",
    className,
    textSize && "text-size",
    children ? "has-content" : undefined
  );
  const iconClass = symbol ? classNameMapping[symbol] || rawSymbolClass : rawSymbolClass;

  const iconClsName = combineClassNames(
    "icon",
    gradientMode || gradientAccent ? "text-gradient-mode" : undefined,
    gradientAccent ? "bg-gradient-primary-accent" : undefined,
    spin ? "fa-spin" : undefined,
    iconClassName
  );
  const iElementClsName = combineClassNames(iconClass, iconClsName);

  if (color || successColor || errorColor || mutedColor) {
    if (!color) {
      color = successColor ? "#5FC375" : errorColor ? "#FF7161" : "#A8A8A8";
    }
    style = style ? { ...style } : {};
    style.color = color;
  }

  const titleToRender = typeof title === "string" && !noTranslate ? tr(title) : title;

  return (
    <div className={clsName} title={titleToRender} style={style} {...restProps}>
      {iconClass && <i className={iElementClsName} />}
      {icon && <FontAwesomeIcon icon={icon} className={iconClassName} />} {children}
    </div>
  );
}

export function ClickableIcon(props: IconProps) {
  const { className, ...restProps } = props;
  const cls = combineClassNames("clickable-icon", className);

  return <Icon className={cls} {...restProps} />;
}

interface ClickableIconsGroupProps extends React.HTMLAttributes<HTMLDivElement> {}

export function ClickableIconsGroup(props: ClickableIconsGroupProps) {
  const { className, ...restAttrs } = props;
  const cls = combineClassNames("clickable-icons-group", className);

  return <div className={cls} {...restAttrs} />;
}

export function OperationIcon<T = any>(props: OperationIconProps<T>) {
  const {
    operation,
    onSuccess,
    onError,
    showSuccessIcon,
    resetStateTrigger,
    resetStateAfterDelay,
    onBeforeOperationCheck,
    ...restProps
  } = props;
  const op = usePendingOperation();
  const onBeforeOperationCheckRef = useRef(onBeforeOperationCheck);
  onBeforeOperationCheckRef.current = onBeforeOperationCheck;

  useEffect(() => {
    op.handle(undefined);
  }, [op, resetStateTrigger]);

  useDelayedEffect(
    () => {
      if (!resetStateAfterDelay) return;
      op.handle(undefined);
    },
    resetStateAfterDelay || 0,
    [op.state.success, op.state.error]
  );

  const performOperation = useCallback(
    (ev: React.MouseEvent) => {
      domEventHelpers.stopPropagationAndPrevent(ev);
      if (op.syncPending) return;

      const checkPromise = onBeforeOperationCheckRef.current
        ? onBeforeOperationCheckRef.current()
        : Promise.resolve(true);
      checkPromise.then(proceed => {
        if (!proceed) return;

        if (op.syncPending) return;

        op.handle(
          operation().then(
            res => {
              onSuccess && onSuccess(res);
            },
            err => {
              onError && onError(err);
              throw err;
            }
          )
        );
      });
    },
    [op, onError, onSuccess, operation]
  );

  if (op.state.pending) return <LoadingSpinner />;
  if (op.state.error) return <Icon symbol="exclamationTriangle" title={op.state.errorMessage} color="#FF7161" />;
  if (op.state.success && showSuccessIcon) return <Icon symbol="check" color="#5FC375" />;

  return <ClickableIcon {...restProps} onClick={performOperation} />;
}

export function RemoveEntityIcon<T = any>(props: RemoveEntityIconProps<T>) {
  const { removeConfirmMessageFactory, symbol, title, ...restProps } = props;
  const removeConfirmMessageFactoryRef = useRef(removeConfirmMessageFactory);
  removeConfirmMessageFactoryRef.current = removeConfirmMessageFactory;

  const confirmDeleteCheck = useCallback(() => {
    const confirmMesage = removeConfirmMessageFactoryRef.current();

    return new Promise<boolean>(resolve => {
      const dialog = generalDialogs.deleteConfirm({
        question: confirmMesage,
      });
      dialog.onNo(() => resolve(false));
      dialog.onYes(() => resolve(true));
    });
  }, []);

  return (
    <OperationIcon
      symbol={symbol || "trashAlt"}
      title={title || "Usuń"}
      showSuccessIcon
      {...restProps}
      onBeforeOperationCheck={confirmDeleteCheck}
    />
  );
}
