import { defineWindowDetails, useInitWindowSize } from "core/desktop";
import { WindowLazyContent } from "core/desktop/WindowLazyContent";
import { tx } from "core/intl";
import { lazy } from "react";

const IconLegendWindowContent = lazy(() =>
  import("./IconLegendWindowContent").then(m => ({ default: m.IconLegendWindowContent }))
);

defineWindowDetails(IconLegendWindow, {
  icon: "fas fa-grip-horizontal",
  title: tx("Legenda symboli"),
  contextHelpCode: "IconLegendWindow",
  noPadding: true,
  allowInPresentationMode: true,
  isAvailable: ss => {
    const { privs } = ss;
    return (
      privs.itcView ||
      privs.busView ||
      privs.spvView ||
      privs.outputDeviceView ||
      privs.parkingSystemView ||
      privs.vmsView ||
      false
    );
  },
});

export function IconLegendWindow() {
  useInitWindowSize(900, 600);

  return (
    <WindowLazyContent>
      <IconLegendWindowContent />
    </WindowLazyContent>
  );
}
