import React from "react";
import { filesService } from "../../apinet";
import { tx } from "core/intl";
import { defineWindowDetails } from "../../core/desktop/Window";
import { useWindowSize, useWindowTitleExtension } from "../../core/desktop/WindowViewContext";
import { Grid, GridItem } from "../../core/layout/Grid";
import { PullRight } from "../../core/layout/PullRight";
import { SimpleContainer } from "../../core/layout/SimpleContainer";
import { useDataLoader } from "../../core/utils/dataHooks";
import { fileOpener } from "../../core/utils/fileOpener";
import { FileSize } from "../../core/utils/FileSize";
import { ClickableIcon } from "../../core/utils/Icon";
import { FileContentPreview } from "./FileContentPreview";
import { Text } from "core/content";

interface FileViewWindowProps {
  fileId: number;
}

defineWindowDetails(FileViewWindow, {
  icon: "fas fa-file",
  title: tx("Podgląd pliku"),
});

export function FileViewWindow(props: FileViewWindowProps) {
  const { fileId } = props;

  useWindowSize(800, 600);
  useWindowTitleExtension(` #${fileId}`);

  const [metadata, metadataOp] = useDataLoader(() => filesService.getMetadataById({ fileId }));
  const isError = metadataOp.state.error;

  if (isError) {
    return (
      <SimpleContainer>
        <Text block className="text-error">
          Błąd wczytywania pliku
        </Text>
        <div>{metadataOp.state.errorMessage}</div>
      </SimpleContainer>
    );
  }

  return (
    <Grid gap>
      <GridItem rowTemplate="auto">
        {metadata && (
          <PullRight>
            <ClickableIcon
              symbol="windoweRestore"
              title="Otwórz w oknie przeglądarki"
              onClick={() => fileOpener.openByIdInNewWindow(metadata.id)}
            />
          </PullRight>
        )}
        {metadata ? (
          <div>
            {metadata.name}, {metadata.mediaType}, <FileSize bytes={metadata.size} />
          </div>
        ) : (
          <Text>Wczytywanie informacji o pliku...</Text>
        )}
      </GridItem>
      <GridItem rowTemplate="1fr">{metadata && <FileContentPreview metadata={metadata} />}</GridItem>
    </Grid>
  );
}
