import React, { useContext } from "react";
import { FormContext } from "./FormContext";
import { typedMemo } from "core/utils/reactHelpers";
import { CheckboxList } from "../controls/CheckboxList";
import { ItemDisplayFn } from "core/utils";

type CheckboxListProps = React.ComponentProps<typeof CheckboxList>;

interface CheckboxListFieldProps<TItem> extends Omit<CheckboxListProps, "value" | "onChange" | "items" | "display"> {
  name: string;
  items: TItem[];
  display?: keyof TItem | ItemDisplayFn<TItem>;
}

export const CheckboxListField = typedMemo(function CheckboxListField<TItem>(props: CheckboxListFieldProps<TItem>) {
  const form = useContext(FormContext);
  const { name, ...restAttrs } = props;
  const value = form.model[name];

  return (
    <CheckboxList
      {...restAttrs}
      onChange={value => form.handleFieldChange(name, value)}
      value={value}
      disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
    />
  );
});
