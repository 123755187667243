import React from "react";
import { IValidationContext, ValidationContext } from "./ValidationContext";
import { ValidationObjectData } from "./validationResult";

interface ValidationContextRootProps {
  validationObject: ValidationObjectData | undefined;
  children: React.ReactNode;
}

interface ValidationContextRootState extends IValidationContext {}

const emptyValidationObject: ValidationObjectData = {};

export class ValidationContextRoot extends React.Component<ValidationContextRootProps, ValidationContextRootState> {
  constructor(props: ValidationContextRootProps) {
    super(props);

    this.registerFieldHandle = this.registerFieldHandle.bind(this);
    this.unregisterFieldHandle = this.unregisterFieldHandle.bind(this);

    this.state = {
      handledFields: {},
      object: props.validationObject || emptyValidationObject,
      registerFieldHandler: this.registerFieldHandle,
      unregisterFieldHandler: this.unregisterFieldHandle,
    };
  }

  componentDidUpdate(prevProps: ValidationContextRootProps) {
    if (prevProps.validationObject !== this.props.validationObject) {
      this.setState({
        object: this.props.validationObject || emptyValidationObject,
      });
    }
  }

  render() {
    return <ValidationContext.Provider value={this.state}>{this.props.children}</ValidationContext.Provider>;
  }

  registerFieldHandle(field: string) {
    if (!field) return;

    field = field.toLowerCase();

    this.setState(prevState => {
      const newHandledFields = Object.assign({}, prevState.handledFields);
      const counter = newHandledFields[field];
      if (counter) {
        newHandledFields[field] = counter + 1;
      } else {
        newHandledFields[field] = 1;
      }

      return {
        handledFields: newHandledFields,
      };
    });
  }

  unregisterFieldHandle(field: string) {
    if (!field) return;

    field = field.toLowerCase();

    this.setState(prevState => {
      const newHandledFields = Object.assign({}, prevState.handledFields);
      const counter = newHandledFields[field];
      if (counter) {
        newHandledFields[field] = Math.max(0, counter - 1);
        if (newHandledFields[field] === 0) {
          delete newHandledFields[field];
        }
      }

      return {
        handledFields: newHandledFields,
      };
    });
  }
}
