import { useTr } from "core/intl";
import React from "react";
import { FeedbackIcon } from "./FeedbackIcon";
import { ClickableIcon, combineClassNames } from "../utils";
import { FeedbackKind } from "./FeedbackIcon";
import "./FeedbackMessage.scss";

interface FeedbackMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  kind?: FeedbackKind;
  header?: React.ReactNode;
  onClose?: () => any;
  fitContent?: boolean;
  inline?: boolean;
  blockCenter?: boolean;
  buttonsSection?: React.ReactNode;
  buttonsOnRight?: boolean;
}

export function FeedbackMessage(props: FeedbackMessageProps) {
  const tr = useTr();

  let {
    kind,
    header,
    fitContent,
    inline,
    onClose,
    buttonsSection,
    blockCenter,
    buttonsOnRight,
    className,
    children,
    ...restProps
  } = props;
  kind = kind || "info";

  const cls = combineClassNames(
    "feedback-message",
    className,
    kind,
    fitContent ? "fit-content" : undefined,
    inline ? "inline" : undefined,
    header ? "with-header" : undefined,
    blockCenter ? "block-center" : undefined,
    onClose ? "with-close-icon" : undefined,
    buttonsOnRight ? "buttons-on-right" : undefined
  );
  const childrenToRender = typeof children === "string" ? tr(children) : children;

  return (
    <div className={cls} {...restProps}>
      <div className="main-section">
        <FeedbackIcon icon={kind} />
        <div className="content">
          {onClose && <ClickableIcon className="close-icon" symbol="xmark" onClick={onClose} />}
          {header && <span className="header">{typeof header === "string" ? tr(header) : header}</span>}
          <span className="message">{childrenToRender}</span>
        </div>
      </div>
      {buttonsSection && <div className="buttons-section">{buttonsSection}</div>}
    </div>
  );
}
