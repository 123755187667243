import { ClickableIcon, combineClassNames } from "core/utils";
import React from "react";
import "./Chip.scss";

interface ChipProps extends React.HTMLProps<HTMLDivElement> {
  textEllipsis?: boolean;
  closeAction?: () => any;
}

export function Chip(props: ChipProps) {
  const { className, closeAction, textEllipsis, children, ...restProps } =
    props;

  const cls = combineClassNames(
    "chip",
    props.onClick ? "clickable" : undefined,
    textEllipsis && "text-ellipsis",
    className
  );

  return (
    <div className={cls} {...restProps}>
      <span className="chip-content">{children}</span>
      {closeAction && (
        <ClickableIcon
          symbol="times"
          className="chip-close-action"
          onClick={closeAction}
        />
      )}
    </div>
  );
}
