import { ShadowScrollableContainer } from "core/content";
import { useTr } from "core/intl";
import { Icon, IconSymbol } from "core/utils";
import React from "react";
import { combineClassNames, toClassNames } from "../utils/reactHelpers";

interface ListProps {
  clickable?: boolean;
  selectable?: boolean;
  nowrap?: boolean;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  children: React.ReactNode;
}

interface ListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  selected?: boolean;
  removed?: boolean;
  iconSymbol?: IconSymbol;
  iconNode?: React.ReactNode;
  children: React.ReactNode;
}

export class List extends React.PureComponent<ListProps> {
  render() {
    const props = this.props;
    let className = "list";
    if (props.clickable) className += " clickable";
    if (props.selectable) className += " selectable";
    if (props.nowrap) className += " nowrap";

    return (
      <ShadowScrollableContainer onScroll={props.onScroll}>
        <ul className={className}>{props.children}</ul>
      </ShadowScrollableContainer>
    );
  }
}

export const ListItem = React.memo(function ListItem(props: ListItemProps) {
  const { selected, removed, className, iconSymbol, iconNode, children, ...restProps } = props;
  const icon = iconNode || (iconSymbol && <Icon symbol={iconSymbol} />);
  const cls = combineClassNames(toClassNames({ selected, removed, "with-icon": Boolean(icon) }), className);
  const tr = useTr();
  const childrenToRender = typeof children === "string" ? tr(children) : children;

  return (
    <li {...restProps} className={cls}>
      {icon ? (
        <>
          <div className="icon-container">{icon}</div>
          <div className="content">{childrenToRender}</div>
        </>
      ) : (
        childrenToRender
      )}
    </li>
  );
});
