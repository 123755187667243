import React, { KeyboardEventHandler } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ClickableIcon, combineClassNames } from "../../utils";
import { IHasInnerRef } from "core/utils/innerRef";

interface TextInputProps extends WithTranslation, Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  value: string | undefined;
  onChange: (value: string) => any;
  name?: string;
  type?: "text" | "password" | "color";
  maxLength?: number;
  onEnter?: KeyboardEventHandler<HTMLInputElement>;
  restrict?: RegExp;
  trim?: boolean;
  debounce?: number;
  showPasswordOption?: boolean;
  errorAnnotation?: string;
  helperIcon?: React.ReactNode;
  noTranslate?: boolean;
  trContext?: string;
}

interface TextInputState {
  isInputFocused: boolean;
  isDirty: boolean;
  inputValue: string;
  showPassword: boolean;
}

export const TextInput = withTranslation()(
  class TextInput extends React.PureComponent<TextInputProps, TextInputState> implements IHasInnerRef {
    inputRef = React.createRef<HTMLInputElement>();

    private debouncedOnChangeTimeout: number | undefined;
    private debouncedValueToCommit: string | undefined;

    constructor(props: TextInputProps) {
      super(props);

      this.onFocus = this.onFocus.bind(this);
      this.onBlur = this.onBlur.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onKeyDown = this.onKeyDown.bind(this);
      this.toggleShowPassword = this.toggleShowPassword.bind(this);

      this.state = {
        inputValue: "",
        isInputFocused: false,
        isDirty: false,
        showPassword: false,
      };
    }

    getInnerRef(): HTMLElement | null {
      return this.inputRef.current;
    }

    render() {
      const {
        value,
        trim,
        type,
        onEnter,
        errorAnnotation,
        placeholder,
        className,
        restrict,
        debounce,
        noTranslate,
        showPasswordOption,
        t,
        tReady,
        i18n,
        ...restAttrs
      } = this.props;
      const viewValue = this.getViewValue();
      const helperIcon = this.renderHelperIcon();
      const clsWrapper = combineClassNames(
        "input-wrapper",
        helperIcon ? "has-helper-icon" : undefined,
        this.state.isInputFocused && "focused",
        this.props.disabled && "disabled",
        value ? "filled" : "empty"
      );
      const clsInput = combineClassNames(className, errorAnnotation && "has-error");
      const placeholderToRender = typeof placeholder === "string" && !noTranslate ? t(placeholder) : placeholder;
      const inputRenderType = type === "password" && this.state.showPassword ? "text" : type || "text";

      return (
        <div className={clsWrapper}>
          <input
            {...restAttrs}
            className={clsInput}
            ref={this.inputRef}
            type={inputRenderType}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            value={viewValue}
            placeholder={placeholderToRender}
          />
          {helperIcon}
          {errorAnnotation && <div className="error-field-annotation">{errorAnnotation}</div>}
        </div>
      );
    }

    renderHelperIcon() {
      if (this.props.type === "password" && this.props.showPasswordOption) {
        return (
          <ClickableIcon
            className="input-icon"
            symbol={this.state.showPassword ? "eyeSlashLight" : "eyeLight"}
            title="Pokaż/ukryj wartość"
            onClick={this.toggleShowPassword}
          />
        );
      }

      if (this.props.helperIcon) {
        return this.props.helperIcon;
      }

      return null;
    }

    componentWillUnmount(): void {
      this.clearDebouncedOnChange();
    }

    private onChange(ev: React.ChangeEvent<HTMLInputElement>) {
      let viewModel = ev.target.value;

      if (this.isChangeFromPasteAction(ev)) {
        viewModel = (viewModel || "").trim();
      }

      viewModel = this.restrictViewModel(viewModel);
      let restrictedNewModelValue = this.restrictNewValueForModel(viewModel);
      this.callOnChange(restrictedNewModelValue);
      this.setState({
        inputValue: viewModel,
        isDirty: true,
      });
    }

    private callOnChange(value: string) {
      this.clearDebouncedOnChange();

      if (this.props.debounce) {
        this.debouncedValueToCommit = value;
        this.debouncedOnChangeTimeout = window.setTimeout(() => {
          this.props.onChange(value);
          this.clearDebouncedOnChange();
        }, this.props.debounce);
      } else {
        this.props.onChange(value);
      }
    }

    private clearDebouncedOnChange() {
      if (this.debouncedOnChangeTimeout) {
        window.clearTimeout(this.debouncedOnChangeTimeout);
      }

      this.debouncedOnChangeTimeout = undefined;
      this.debouncedValueToCommit = undefined;
    }

    private commitWaitingDebouncedValue() {
      if (this.debouncedValueToCommit !== undefined && this.debouncedOnChangeTimeout) {
        this.props.onChange(this.debouncedValueToCommit);
        this.clearDebouncedOnChange();
      }
    }

    private onKeyDown(ev: React.KeyboardEvent<HTMLInputElement>) {
      this.props.onKeyDown && this.props.onKeyDown(ev);

      if (ev.key === "Enter") {
        this.commitWaitingDebouncedValue();
        this.props.onEnter && this.props.onEnter(ev);
      }
    }

    private onFocus(ev: React.FocusEvent<HTMLInputElement>) {
      this.setState({
        isInputFocused: true,
        isDirty: false,
        inputValue: ev.target.value,
      });
      this.inputRef.current && this.inputRef.current.select();
      this.props.onFocus && this.props.onFocus(ev);
    }

    private onBlur(ev: React.FocusEvent<HTMLInputElement>) {
      this.commitWaitingDebouncedValue();
      this.setState({
        isInputFocused: false,
        isDirty: false,
        inputValue: "",
      });
      this.props.onBlur && this.props.onBlur(ev);
    }

    private getViewValue(): string {
      if (this.state.isInputFocused && this.state.isDirty) {
        return this.state.inputValue;
      } else {
        return this.props.value || "";
      }
    }

    private restrictViewModel(value: string): string {
      if (this.props.restrict) {
        const match = this.props.restrict.exec(value);
        value = (match && match[0]) || "";
      }

      if (this.props.maxLength && value.length > this.props.maxLength) {
        value = value.substring(0, this.props.maxLength);
      }

      return value;
    }

    private restrictNewValueForModel(value: string): string {
      if (this.props.trim) {
        value = value.trim();
      }

      return value;
    }

    private isChangeFromPasteAction(ev: React.ChangeEvent<HTMLInputElement>): boolean {
      const nativeEv = ev.nativeEvent as InputEvent;
      return nativeEv.inputType === "insertFromPaste";
    }

    private toggleShowPassword() {
      this.setState({
        showPassword: !this.state.showPassword,
      });
    }
  }
);
