import React, { useContext } from "react";
import { Checkbox } from "../controls";
import { FormContext } from "./FormContext";

type CheckboxProps = React.ComponentProps<typeof Checkbox>;

interface CheckboxFieldProps extends Omit<CheckboxProps, "value" | "onChange"> {
  name: string;
}

export const CheckboxField = React.memo(function CheckboxField(props: CheckboxFieldProps) {
  const form = useContext(FormContext);
  const { name, ...restAttrs } = props;
  const value = form.model[name];

  return (
    <Checkbox
      {...restAttrs}
      onChange={value => form.handleFieldChange(name, value)}
      value={value}
      disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
    />
  );
});
