import { Text } from "core/content";
import React from "react";
import { combineClassNames } from "../../utils";
import "./Checkbox.scss";

interface CheckboxProps {
  value: boolean | undefined;
  onChange: (value: boolean) => any;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
  className?: string;
  inline?: boolean;
  noTranslate?: boolean;
}

export class Checkbox extends React.PureComponent<CheckboxProps> {
  constructor(props: CheckboxProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  render() {
    const props = this.props;
    const cls = combineClassNames(
      "checkbox",
      props.className,
      props.disabled ? "disabled" : undefined,
      props.inline ? "inline" : undefined
    );

    const inputCheckbox = (
      <input
        type="checkbox"
        onChange={this.onChange}
        checked={props.value}
        style={props.style}
        disabled={props.disabled}
      />
    );

    return (
      <label className={cls}>
        {inputCheckbox}
        {props.children && (
          <Text block noTranslate={props.noTranslate} className="checkbox-label">
            {props.children}
          </Text>
        )}
      </label>
    );
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange(ev.target.checked);
  }
}
