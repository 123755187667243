import { stringUtils } from "./stringUtils";

type MultiTokenFilteringFn = (searchValue: string) => boolean;

export type MultiTokenFilteringMode = 'startsWith' | 'includes';

export const multiTokenFiltering = {
  getPredicate: getMultiTokenFilteringFunction
}

function getMultiTokenFilteringFunction(filteringMode: MultiTokenFilteringMode, searchText: string): MultiTokenFilteringFn {
  const tokens = stringUtils.removePolishChars(searchText).toLowerCase().split(' ').filter(Boolean);

  if (filteringMode === 'includes') {
    return (searchValue: string) =>  {
      const searchValueLower = stringUtils.removePolishChars(searchValue.toLowerCase());
      return tokens.every(t => searchValueLower.includes(t));
    }
  }
  else {
    return (searchValue: string) =>  {
      const regexTokens = tokens.map(x => new RegExp(`(^|[ /[&{'„"])${stringUtils.escapeRegex(x)}`))
      const searchValueLower = stringUtils.removePolishChars(searchValue.toLowerCase());
      return regexTokens.every(t => t.test(searchValueLower));
    }
  }
}