import { useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "./utils";

export function useDebounceValue<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debounceReaction = useDebounce(() => setDebouncedValue(value), delay);
  useEffect(debounceReaction, [value, debounceReaction]);

  return debouncedValue;
}

export function useDebounce<T extends Function>(fn: T, delay: number): T {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  const destryoyedRef = useRef(false);

  useEffect(() => {
    return () => {
      destryoyedRef.current = true;
    }
  }, []);

  const debouncedFn = useMemo(() => {
    return debounce(function (this: any) {
      if (destryoyedRef.current) return;

      fnRef.current.apply(this, arguments);
    }, delay) as any;
  }, [delay]);

  return debouncedFn;
}
