import React, { Dispatch, SetStateAction, useMemo } from "react";
import { FormContext, IFormContext } from "./FormContext";
import { FormDiv } from "../FormDiv";
import { evaluteSyncSetStateAction } from "core/utils/reactHelpers";
import { ValidationContextRoot, ValidationObjectData } from "core/validation";
import { FormItemWithUnhandledValidationErrors } from "./FormItemWithUnhandledValidationErrors";

interface FormProps<TModel> {
  model?: TModel;
  onChange?: (model: TModel) => any;
  onChangeDispatch?: Dispatch<SetStateAction<TModel>>;
  onSubmit?: (model: TModel) => any;
  disabled?: boolean;
  className?: string;
  // TODO replace by using only nestedFormField and nestedFormFieldIndex?
  parentFieldName?: string;
  /** Used internally by FormNesting */
  nestedFormField?: string;
  /** Used internally by FormNesting */
  nestedFormFieldIndex?: number;
  rootComponent?: React.ComponentType | string;
  validationObject?: ValidationObjectData;
  children?: React.ReactNode;
}

type BaseFormModel = { [key: string]: any };

export class Form<TModel extends BaseFormModel = any> extends React.PureComponent<FormProps<TModel>> {
  static defaultRootComponent: React.ComponentType | string = FormDiv;

  private currentSyncFrameEvaluatedModel?: TModel;
  private multipleSyncFrameModelEvaluationWarnEmitted = false;

  constructor(props: FormProps<TModel>) {
    super(props);

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {};
  }

  render() {
    const Root = this.props.rootComponent || Form.defaultRootComponent || React.Fragment;
    let rootProps: any = {};
    if (this.props.rootComponent === "form") {
      rootProps.onSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
      };
    }

    if (this.props.className) {
      rootProps.className = this.props.className;
    }

    let formContent = (
      <Root {...rootProps}>
        {this.props.children}
        {this.props.validationObject && <FormItemWithUnhandledValidationErrors />}
      </Root>
    );
    
    if (this.props.validationObject) {
      formContent = (
        <ValidationContextRoot validationObject={this.props.validationObject}>{formContent}</ValidationContextRoot>
      );
    }

    return <FormContextRoot formInstance={this}>{formContent}</FormContextRoot>;
  }

  handleFieldChange<K extends keyof TModel>(key: K, value: TModel[K]) {
    this.setModel(prevModel => ({
      ...prevModel,
      [key]: value,
    }));
  }

  handleModelChange(action: SetStateAction<TModel>) {
    this.setModel(action);
  }

  setModel(action: SetStateAction<TModel>) {
    if (!this.props.onChange && !this.props.onChangeDispatch) {
      console.warn(
        `Form component received model update but onChange/onChangeDispatch callback is not defined - model update will not be performed.`
      );
      return;
    }

    if (this.props.onChangeDispatch && this.props.onChange) {
      console.warn(
        "Form: onChangeDispatch and onChange defined at same time, onChangeDispatch have priority, onChange will not be called"
      );
    }

    if (this.props.onChangeDispatch) {
      this.props.onChangeDispatch(action);
    } else if (this.props.onChange) {
      const newModel = this.getSyncEvaluatedSetStateActionOnModel(action);
      this.props.onChange(newModel);
    }
  }

  getSyncEvaluatedSetStateActionOnModel(action: SetStateAction<TModel>): TModel {
    const lastEvaluationModel = this.getLastModelForSyncActionEvaluation();

    if (!this.currentSyncFrameEvaluatedModel) {
      Promise.resolve().then(() => (this.currentSyncFrameEvaluatedModel = undefined));
    }

    this.currentSyncFrameEvaluatedModel = evaluteSyncSetStateAction(lastEvaluationModel, action);
    return this.currentSyncFrameEvaluatedModel;
  }

  getLastModelForSyncActionEvaluation(): TModel {
    if (this.currentSyncFrameEvaluatedModel) {
      if (!this.multipleSyncFrameModelEvaluationWarnEmitted) {
        console.warn(`Multiple sync <Form /> model mutations in the same javascript frame.
 Performed best effort sync updates model merging but consider using <Form onChangeDispatch={...}
 instead of <Form onChange={...} to use native react state update batching.`);
        this.multipleSyncFrameModelEvaluationWarnEmitted = true;
      }

      return this.currentSyncFrameEvaluatedModel;
    }

    return this.props.model!;
  }

  handleSubmit() {
    this.props.onSubmit && this.props.model && this.props.onSubmit(this.props.model);
  }
}

interface FormContextRootProps<TModel extends BaseFormModel> {
  formInstance: Form<TModel>;
  children: React.ReactNode;
}

const emptyFallbackModel: any = {};

function FormContextRoot<TModel extends BaseFormModel>(props: FormContextRootProps<TModel>) {
  const { formInstance, children } = props;
  const { disabled, model, parentFieldName, nestedFormField, nestedFormFieldIndex } = formInstance.props;

  const formCtxValue = useMemo<IFormContext<TModel>>(
    () => ({
      handleFieldChange: formInstance.handleFieldChange,
      handleModelChange: formInstance.handleModelChange,
      handleSubmit: formInstance.handleSubmit,
      model: model || emptyFallbackModel,
      disabled: disabled || false,
      parentFieldName: parentFieldName || "",
      nestedFormField,
      nestedFormFieldIndex,
    }),
    [formInstance, model, disabled, parentFieldName, nestedFormField, nestedFormFieldIndex]
  );

  return <FormContext.Provider value={formCtxValue}>{children}</FormContext.Provider>;
}
