const minTop = 20;
const minLeft = 300;
const maxTop = 150;
const maxLeft = 450;
const step = 30;

let top = minTop;
let left = minLeft;

export function getNextPosition(): [number, number] {
  const result: [number, number] = [left + top, top];

  top += step;
  if (top > maxTop) {
    top = minTop;

    left += step;
    if (left > maxLeft) {
      left = minLeft;
    }
  }

  return result;
}