import { useValidationForField } from "core/validation";
import React, { useContext } from "react";
import { NumberInput } from "../controls/NumberInput";
import { FormContext } from "./FormContext";

type NumberInputProps = React.ComponentProps<typeof NumberInput>;

interface NumberFieldProps extends Omit<NumberInputProps, "value" | "onChange" | "onEnter"> {
  name: string;
}

export const NumberField = React.memo(function NumberField(props: NumberFieldProps) {
  const form = useContext(FormContext);
  const { name, ...restProps } = props;
  const validation = useValidationForField(name);
  const value = form.model[props.name];

  return (
    <NumberInput
      {...restProps}
      onChange={value => form.handleFieldChange(props.name, value)}
      value={value}
      onEnter={form.handleSubmit}
      disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
      errorAnnotation={validation?.aggregatedMessage}
    />
  );
});
