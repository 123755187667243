import { useEffect, useRef } from "react";
import { BaseEvent } from "common/eventBusTypes";

export type BusEventHandler<T extends BaseEvent> = (ev: T) => any;

interface Subscriptions {
  [key: string]: {
    [key: number]: BusEventHandler<any>;
  };
}

export interface EventBusSubscription {
  unsubscribe(): void;
}

let subId = 1;
const subscriptions: Subscriptions = {};

export const eventBus = {
  subscribe,
  publish,
  useEvent,
};

function subscribe<T extends BaseEvent>(
  eventCodeOrClass: T["code"] | { new (): BaseEvent },
  handler: BusEventHandler<T>
): EventBusSubscription {
  const eventCode = getEventCode(eventCodeOrClass);
  const id = subId++;

  if (!subscriptions[eventCode]) {
    subscriptions[eventCode] = {};
  }
  subscriptions[eventCode][id] = handler;

  return {
    unsubscribe: () => {
      delete subscriptions[eventCode][id];
      if (Object.keys(subscriptions[eventCode]).length === 0) {
        delete subscriptions[eventCode];
      }
    },
  };
}

function getEventCode(eventCodeOrClass: string | { new (): BaseEvent }): string {
  if (typeof eventCodeOrClass === "function") {
    const x: BaseEvent = new eventCodeOrClass();
    if (!x.code) throw new Error("Expected code field in " + eventCodeOrClass);
    return x.code;
  } else {
    return eventCodeOrClass;
  }
}

function publish<T extends BaseEvent>(ev: T) {
  if (!subscriptions[ev.code]) return;

  Object.keys(subscriptions[ev.code]).forEach((id: any) => {
    try {
      subscriptions[ev.code][id](ev);
    } catch (e) {
      console.error(`Error while processing event handler for code '${ev.code}' handler id ${id}`);
      console.error(e);
    }
  });
}

function useEvent<T extends BaseEvent>(code: T["code"], handler: BusEventHandler<T>) {
  const handlerRef = useRef<any>(handler);
  handlerRef.current = handler;

  useEffect(() => {
    function handleFn() {
      handlerRef.current.apply(null, arguments);
    }

    const sub = eventBus.subscribe<T>(code, handleFn as any);

    return () => {
      sub.unsubscribe();
    };
  }, [code]);
}
