import { useValidationForField } from "core/validation";
import React, { useContext } from "react";
import { TextInput } from "../controls/TextInput";
import { FormContext } from "./FormContext";

type TextInputProps = React.ComponentProps<typeof TextInput>;

interface TextFieldProps extends Omit<TextInputProps, "value" | "onChange" | "onEnter"> {
  name: string;
  inputName?: string;
}

export const TextField = React.memo(function TextField(props: TextFieldProps) {
  const form = useContext(FormContext);
  const { name, inputName, ...restAttrs } = props;
  const validation = useValidationForField(name);
  const value = form.model[name] || "";

  return (
    <TextInput
      {...restAttrs}
      onChange={value => form.handleFieldChange(name, value)}
      value={value}
      name={inputName}
      onEnter={form.handleSubmit}
      disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
      errorAnnotation={validation?.aggregatedMessage}
    />
  );
});
