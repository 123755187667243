import { defineWindowDetails, useInitiallyMaximizedWindow, useInitWindowSize } from "core/desktop";
import { WindowLazyContent } from "core/desktop/WindowLazyContent";
import { tx } from "core/intl";
import { lazy } from "react";

type RulesWindowProps = React.ComponentProps<typeof RulesWindowContent>;

const RulesWindowContent = lazy(() =>
  import("./RulesWindowContent").then(m => ({ default: m.RulesWindowContent }))
);

defineWindowDetails(RulesWindow, {
  icon: "fas fa-circle-nodes",
  title: tx("Business rules"),
  isAvailable: ss => ss.privs.businessRuleView,
});

export function RulesWindow(props: RulesWindowProps) {
  useInitWindowSize(1000, 700);
  useInitiallyMaximizedWindow();

  return (
    <WindowLazyContent>
      <RulesWindowContent {...props} />
    </WindowLazyContent>
  );
}
