import React, { useMemo, useRef } from "react";
import "./LogoSvgImg.scss";
import { Img } from "core/content/Img";
import { combineClassNames, toClassNames, useDataLoader } from ".";
import axios from "axios";
import { systemState } from "core/global";

type ImgProps = React.ComponentProps<typeof Img>;

interface LogoSvgImgProps extends Omit<ImgProps, "src" | "base64" | "base64ImageType"> {
  fromTenant?: boolean;
  buttonVariant?: boolean;
}

const logoPromiseByVariant: Record<string, Promise<string> | undefined> = {};
const logoContentByVariant: Record<string, string | undefined> = {};

export function LogoSvgImg(props: LogoSvgImgProps) {
  const { className, fromTenant, buttonVariant, ...restProps } = props;
  const variant = useMemo(() => getLogoVariant(fromTenant, buttonVariant), [fromTenant, buttonVariant]);
  const content = logoContentByVariant[variant];
  const lazyLoaded = useRef(content === undefined);
  const [data] = useDataLoader(
    async () => (lazyLoaded.current ? fetchLogo(fromTenant || false, buttonVariant || false) : undefined),
    [fromTenant, buttonVariant]
  );
  const imgCls = toClassNames({
    lazy: lazyLoaded.current,
    loaded: Boolean(data),
  });
  const imgFinalCls = combineClassNames("logo-svg", className, imgCls);

  return <Img base64={content || data} base64ImageType="image/svg+xml" className={imgFinalCls} {...restProps} />;
}

function fetchLogo(fromTenant: boolean | undefined, buttonVariant: boolean | undefined) {
  const variant = getLogoVariant(fromTenant, buttonVariant);

  if (!logoPromiseByVariant[variant]) {
    logoPromiseByVariant[variant] = axios
      .get<string>(`api/clientconfig/getlogosvg?encoded=true&buttonVariant=${buttonVariant || false}`)
      .then(res => {
        logoContentByVariant[variant] = res.data;
        return res.data;
      });
  }

  return logoPromiseByVariant[variant];
}

function getLogoVariant(fromTenant: boolean | undefined, buttonVariant: boolean | undefined) {
  const tenantId = fromTenant ? systemState.state.user?.singleTenantId : undefined;
  return `${tenantId}|${buttonVariant || false}`;
}
