import { LogoWatermark } from "core/utils/LogoWatermark";
import "./MainViewSuspenseFallback.scss";
import { useTr } from "core/intl";

export function MainViewSuspenseFallback() {
  const tr = useTr();

  return (
    <div className="main-view-suspense-fallback">
      <LogoWatermark annotation={tr("Loading desktop...")} fromTenant />
    </div>
  );
}
