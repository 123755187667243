import React from "react";

interface TableProps {
  children?: React.ReactNode;
  scrollable?: boolean;
  fixedHeaders?: boolean;
  hover?: boolean;
  compressed?: boolean;
  className?: string;
  onScroll?: React.UIEventHandler;
}

export class Table extends React.PureComponent<TableProps> {
  render() {
    if (this.props.scrollable) {
      return (
        <div style={{ height: "100%" }} className="scrollable" onScroll={this.props.onScroll}>
          {this.renderTable()}
        </div>
      );
    } else {
      return this.renderTable();
    }
  }

  renderTable() {
    return <table className={this.getClassName()}>{this.props.children}</table>;
  }

  getClassName() {
    const props = this.props;

    return [
      "data-table",
      (props.scrollable || this.props.fixedHeaders) && "fixed-headers",
      props.hover && "hover",
      props.compressed && "compressed",
      props.className,
    ]
      .filter((x) => x)
      .join(" ");
  }
}
