import { Suspense } from "react";
import { StandardLazyComponentFallback } from "core/utils/StandardLazyComponentFallback";

interface WindowLazyContentProps {
  children: React.ReactNode;
}

export function WindowLazyContent(props: WindowLazyContentProps) {
  return <Suspense fallback={<StandardLazyComponentFallback />}>{props.children}</Suspense>;
}
