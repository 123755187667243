import { useCallback, useState } from "react";

type ScrolledTopHook = [boolean, React.UIEventHandler<HTMLElement>];

export function useScrolledTop(): ScrolledTopHook {
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const onScroll = useCallback<React.UIEventHandler<HTMLDivElement>>((ev) => {
    const div = ev.target as HTMLDivElement;
    setIsScrolledToTop(div.scrollTop === 0);
  }, []);

  return [isScrolledToTop, onScroll];
}
