import React, { createRef } from "react";
import ReactDOM from "react-dom";
import { PopperTrigger, TriggerType } from "./PopperTrigger";
import { Placement } from "popper.js";
import { TooltipElement } from "./TooltipElement";
import { getBodyPortal } from "../utils/reactHelpers";

interface TooltipProps {
  children: React.ReactElement<any> | number | string;
  content: React.ReactNode;
  placement?: Placement;
  trigger?: TriggerType | TriggerType[];
  disabled?: boolean;
}

export class Tooltip extends React.Component<TooltipProps> {
  static defaultTrigger: TriggerType | TriggerType[] = "hover";

  popperTriggerRef = createRef<PopperTrigger>();
  popperRef = createRef<HTMLElement>();

  constructor(props: TooltipProps) {
    super(props);

    this.renderTooltipElement = this.renderTooltipElement.bind(this);

    this.state = {};
  }

  render() {
    const trigger = this.props.trigger || Tooltip.defaultTrigger;

    return (
      <PopperTrigger
        ref={this.popperTriggerRef}
        popperRef={this.popperRef}
        trigger={trigger}
        content={this.renderTooltipElement}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </PopperTrigger>
    );
  }

  renderTooltipElement() {
    if (!this.popperRef.current) return null;

    return ReactDOM.createPortal(
      <TooltipElement popperRef={this.popperRef.current} placement={this.props.placement}>
        {this.props.content}
      </TooltipElement>,
      getBodyPortal()
    );
  }
}
