import React from "react";
import { combineClassNames } from "../utils";
import "./ButtonGroup.scss";

interface ButtonGroupProps {
  children: React.ReactNode;
  vertical?: boolean;
}

export function ButtonGroup(props: ButtonGroupProps) {
  const cls = combineClassNames(
    "button-group",
    props.vertical ? "vertical" : undefined
  );
  return <div className={cls}>{props.children}</div>;
}
