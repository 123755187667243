import React from "react";
import { Text } from "../content";
import { combineClassNames } from "../utils";
import "./ClickableText.scss";

interface ClickableTextProps extends React.ComponentProps<typeof Text> {}

export function ClickableText(props: ClickableTextProps) {
  let { children, className, ...restAttrs } = props;
  className = combineClassNames(className, "clickable-text");

  return (
    <Text className={className} {...restAttrs}>
      {children}
    </Text>
  );
}
