import { FormContext } from "core/forms";
import React, { useContext, useLayoutEffect } from "react";
import { ValidationObjectData, ValidationObjectDataValue } from "./validationResult";

export interface IValidationContext {
  object: ValidationObjectData;
  handledFields: { [field: string]: number | undefined };
  registerFieldHandler: (field: string) => any;
  unregisterFieldHandler: (field: string) => any;
}

function noop() {}

export const DefaultValidationContextValue: IValidationContext = {
  object: {},
  handledFields: {},
  registerFieldHandler: noop,
  unregisterFieldHandler: noop,
};

export const ValidationContext = React.createContext<IValidationContext>(DefaultValidationContextValue);

export function useValidationForField(field: string): ValidationObjectDataValue | undefined {
  const validationCtx = useContext(ValidationContext);
  const { parentFieldName } = useContext(FormContext);
  const { registerFieldHandler, unregisterFieldHandler } = validationCtx;

  const handledFieldName = combineHandledFieldName(field, parentFieldName);

  useLayoutEffect(() => {
    registerFieldHandler(handledFieldName);

    return () => {
      unregisterFieldHandler(handledFieldName);
    };
  }, [handledFieldName, registerFieldHandler, unregisterFieldHandler]);

  if (!handledFieldName) return undefined;

  return validationCtx.object[handledFieldName.toLowerCase()];
}

function combineHandledFieldName(field: string, parentFieldName: string): string {
  if (!parentFieldName) return field;

  return `${parentFieldName}.${field}`;
}
