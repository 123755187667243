import React, { useState, useEffect } from "react";

interface ClickableIFrameProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  onContentClick: () => any;
}

export const ClickableIFrame = React.memo(function ClickableIFrame(props: ClickableIFrameProps) {
  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    const onWindowBlur = () => {
      if (isOver) props.onContentClick();
    };

    window.addEventListener("blur", onWindowBlur);

    return () => window.removeEventListener("blur", onWindowBlur);
  }, [isOver]); // eslint-disable-line

  const { onContentClick, ...rest } = props;
  return (
    <iframe {...rest} onMouseOver={() => setIsOver(true)} onMouseOut={() => setIsOver(false)} />
  );
});
