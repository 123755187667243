import { createRoot } from "react-dom/client";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "leaflet/dist/leaflet.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./core/styles/app.scss";
import { App } from "./App";
import Popper from "popper.js";

import "./core/globalSetup";
import "./core/intl/i18nSetup";
import axios from "axios";
import { bundledAppVersionInfo } from "core/bundledAppVersionInfo";

Popper.Defaults.modifiers!.preventOverflow!.boundariesElement = "window";
Popper.Defaults.modifiers!.computeStyle!.gpuAcceleration = false;

axios.defaults.headers.common["x-app-version"] = bundledAppVersionInfo.fullVersion;

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
