import React, { useEffect } from "react";
import "./LoginPage.scss";
import { LoginPageRightContent } from "./LoginPageContent";
import { useDataLoader } from "core/utils";
import { clientConfigService } from "apinet";

export function LoginPage() {
  useWelcomeConfig();
  useEffect(() => {
    document.documentElement.style.fontSize = "1.04822vmin";

    return () => {
      document.documentElement.style.fontSize = "";
    };
  }, []);

  return (
    <div className="login-page">
      <div className="left-column" style={{ backgroundImage: "url('api/ClientConfig/WelcomeImage')" }}></div>
      <div className="right-column">
        <LoginPageRightContent />
      </div>
    </div>
  );
}

function useWelcomeConfig() {
  useDataLoader(() =>
    clientConfigService.welcomeConfig().then(res => {
      window.document.title = res.brandName || "ZIR";
    })
  );
}
